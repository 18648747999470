import { useCallback, useEffect, useState } from "react"
import { Dashboard } from "../entities/Dashboard"
import { useFetchDashboardsQuery } from "../../../store/store"
import { useConfigDashboard } from "./useConfigDashboard"
import { useDeleteDashboard } from "./useDeleteDashboard"

export const useDashboard = () => {
  const {
    data: dashboards,
    isLoading: isLoadingDashboards,
    isError,
    refetch,
  } = useFetchDashboardsQuery()

  const {
    isConfigureModalOpen,
    isActionDisabled,
    form,
    handleOpenEditModal,
    handleOpenCreateModal,
    handleCloseModal,
    configureDashboard,
  } = useConfigDashboard()

  const {
    isDeleteOpen,
    isDeleteDisabled,
    handleOpenDeleteModal,
    handleCloseDeleteModal,
    deleteDashboard,
  } = useDeleteDashboard()

  const [dashboard, setDashboard] = useState<Dashboard | null | undefined>(
    undefined
  )

  const hasError = isError || (!dashboards && !isLoadingDashboards)
  const isLoading = isLoadingDashboards || dashboard === undefined

  useEffect(() => {
    if (isError) {
      setDashboard(null)
      return
    }

    if (!dashboards) return
    if (dashboard) return

    if (dashboards.length) {
      const dashboardId = localStorage.getItem("dashboard")

      if (!dashboardId) {
        setDashboard(dashboards[0])
        localStorage.setItem("dashboard", dashboards[0].id.toString())
        return
      }

      const find = dashboards.find((d) => d.id === parseInt(dashboardId))

      if (!find) {
        setDashboard(dashboards[0])
        localStorage.setItem("dashboard", dashboards[0].id.toString())
        return
      }

      setDashboard(find)

      return
    }

    setDashboard(null)
  }, [dashboard, dashboards, isError])

  const handleRefreshDasboards = () => {
    refetch()
  }

  const handleChangeDashboard = useCallback((dashboard: Dashboard) => {
    setDashboard(dashboard)
    localStorage.setItem("dashboard", dashboard.id.toString())
  }, [])

  const handleConfigureDashboard = () => {
    configureDashboard(dashboard?.id).then((configuredDashboard) =>
      setDashboard(configuredDashboard)
    )
  }

  const handleDeleteDashboard = () => {
    if (!dashboard) return

    deleteDashboard(dashboard.id).then(() => setDashboard(null))
  }

  return {
    dashboard,
    dashboards,
    hasError,
    isLoading,
    isConfigureModalOpen,
    isActionDisabled,
    form,
    isDeleteOpen,
    isDeleteDisabled,
    handleOpenEditModal,
    handleOpenCreateModal,
    handleCloseModal,
    handleConfigureDashboard,
    handleOpenDeleteModal,
    handleCloseDeleteModal,
    handleDeleteDashboard,
    handleRefreshDasboards,
    handleChangeDashboard,
  }
}
