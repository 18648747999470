import {
  Trash,
  Link,
  LinkBreak,
  Gear,
  CaretLineDown,
} from "@phosphor-icons/react"
import { Card } from "../../../../../../../../components/Card"
import {
  AssetHistory,
  AssetUserEvent,
  EventInfo,
} from "../../../../../../../../utils/entities/assets"

import { Divider } from "../../../../../../../../components/ui/Divider"
import React, { useState, useCallback } from "react"
import theme from "../../../../../../../../styles/theme"
import { useAppTranslate } from "../../../../../../../../translate/useAppTranslate"
import {
  EventPlatformType,
  EventUserType,
} from "../../../../../utils/eventType"
import { ModalEvent } from "../ModalEvent"
import { ModalDeletEvent } from "../ModalDeletEvent"

interface HistoryContentProps {
  historyData: AssetHistory[]
  assetId: string
}

export const HistoryContent = ({
  historyData,
  assetId,
}: HistoryContentProps) => {
  const {
    assets: { history },
  } = useAppTranslate()

  const truncateName = (name: string, maxLength: number = 20): string => {
    if (name.length > maxLength) {
      return `${name.slice(0, maxLength)}...`
    }
    return name
  }

  const getHistoryType = useCallback(
    (
      type: string,
      infoAsset: EventInfo | AssetUserEvent,
      latestAssetName: string,
      _assetId: string
    ) => {
      const eventDescriptions = {
        [EventUserType.MAINTENANCE_CORRECTIVE]:
          history.correctiveMaintenanceShutdown,
        [EventUserType.PLANNED_STOP]: history.scheduledShutdown,
        [EventUserType.MAINTENANCE_PREVENTIVE]:
          history.preventiveMaintenanceShutdown,
        [EventUserType.EQUIPMENT_FAILURE]: history.equipmentFailure,
        [EventUserType.OPERATIONAL_STOP]: history.operationalShutdown,
        [EventUserType.INSPECTION]: history.inspection,
        [EventUserType.OTHER]: history.other,
        [EventPlatformType.ASSET_CREATED]: history.assetCreated,
        [EventPlatformType.ASSET_UPDATED]: history.assetUpdated,
        [EventPlatformType.ASSET_DELETED]: history.assetDeleted,
        [EventPlatformType.ASSET_MOVED_TO_NEW_PARENT]:
          history.assetMovedToNewParent,
        [EventPlatformType.SENSOR_LINKED_TO_ASSET]: history.sensorLinkedToAsset,
        [EventPlatformType.SENSOR_UNLINKED_FROM_ASSET]:
          history.sensorUnlinkedFromAsset,
      }

      const name =
        _assetId === assetId ? "" : `${truncateName(latestAssetName)} - `
      const eventTypes: Record<
        string,
        { icon: JSX.Element; title: string; info: string; description: string }
      > = {
        AssetCreated: {
          icon: <CaretLineDown />,
          title: `${name}${history.assetUpdated}`,
          info: history.assetCreated,
          description: history.assetCreated,
        },
        AssetUpdated: {
          icon: <Gear />,
          title: `${name}${history.assetUpdated}`,
          info: history.assetUpdated,
          description: history.assetUpdated,
        },
        SensorUnlinkedFromAsset: {
          icon: <LinkBreak color={theme.palette.error.main} />,
          title: `${name}${history.sensorUnlinkedFromAsset}`,
          info: history.sensorUnlinkedFromAsset,
          description: history.sensorUnlinkedFromAsset,
        },
        AssetMovedToNewParent: {
          icon: <Gear />,
          title: `${name}${history.assetMoved}`,
          info: history.assetMovedToNewParent,
          description: history.assetMovedToNewParent,
        },
        AssetDeleted: {
          icon: <Trash />,
          title: `${name}${history.assetDeleted}`,
          info: history.assetDeleted,
          description: history.assetDeleted,
        },
        SensorLinkedToAsset: {
          icon: <Link color={theme.palette.success.main} />,
          title: `${name}${history.sensorLinkedToAsset}`,
          info: history.sensorLinkedToAsset,
          description: history.sensorLinkedToAsset,
        },
        default: {
          icon: <Gear />,
          title: `${name}${"title" in infoAsset ? infoAsset.title : ""}`,
          info: eventDescriptions[type as keyof typeof eventDescriptions],
          description: "description" in infoAsset ? infoAsset.description : "",
        },
      }

      return eventTypes[type] || eventTypes.default
    },
    [
      history.correctiveMaintenanceShutdown,
      history.scheduledShutdown,
      history.preventiveMaintenanceShutdown,
      history.equipmentFailure,
      history.operationalShutdown,
      history.inspection,
      history.other,
      history.assetCreated,
      history.assetUpdated,
      history.assetDeleted,
      history.assetMovedToNewParent,
      history.sensorLinkedToAsset,
      history.sensorUnlinkedFromAsset,
      history.assetMoved,
      assetId,
    ]
  )

  const [openViewEventModal, setOpenViewEventModal] = useState(false)
  const [openDeleteEventModal, setOpenDeleteEventModal] = useState(false)
  const [infoEvent, setInfoEvent] = useState<AssetHistory | null>(null)

  const handleOpenDeleteEventModal = (data: AssetHistory) => {
    setOpenDeleteEventModal(true)
    setInfoEvent(data)
  }

  const handleOpenViewEventModal = (data: AssetHistory) => {
    setOpenViewEventModal(true)
    setInfoEvent(data)
  }

  return (
    <>
      {historyData?.map((data, index) => {
        const { icon, title, info } = getHistoryType(
          data.eventType,
          data.eventInfo,
          data.latestAssetName,
          data.assetId
        )
        return (
          <React.Fragment key={index}>
            <Card
              title={title}
              icon={icon}
              createdAt={new Date(data.createdAt)}
              executedAt={new Date(data.executedAt)}
              type='notification'
              action={() => handleOpenViewEventModal(data)}
              assetId={Number(data.assetId)}
              eventGroup={data.eventGroup}
            >
              {info}
            </Card>
            <Divider />
          </React.Fragment>
        )
      })}
      {openViewEventModal && infoEvent && (
        <ModalEvent
          handleCloseModal={() => setOpenViewEventModal(false)}
          edit={infoEvent.eventGroup === "user"}
          assetId={infoEvent.assetId}
          titleDefault={infoEvent.latestAssetName}
          descriptionDefault={
            getHistoryType(
              infoEvent.eventType,
              infoEvent.eventInfo,
              infoEvent.latestAssetName,
              infoEvent.assetId
            ).description
          }
          typeDefault={infoEvent.eventType}
          executedAtDefault={infoEvent?.executedAt}
          assetName={
            "name" in infoEvent.eventInfo ? infoEvent.latestAssetName : ""
          }
          typeModal={infoEvent.eventGroup === "user" ? "edit" : "view"}
          eventId={infoEvent.id}
          onClickDelete={() => handleOpenDeleteEventModal(infoEvent)}
        />
      )}

      {openDeleteEventModal && infoEvent && (
        <ModalDeletEvent
          handleCloseModal={() => setOpenDeleteEventModal(false)}
          assetId={infoEvent.assetId}
          eventId={infoEvent.id}
        />
      )}
    </>
  )
}
