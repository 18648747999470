import { useState } from "react"
import { ModalHedro } from "../../../../../../components/ModalHedro"
import { FlexContainer } from "../../../../../../components/ui/FlexContainer"
import { Text } from "../../../../../../components/ui/Text"
import { useDeleteAssetAttachmentMutation } from "../../../../../../store/store"
import theme from "../../../../../../styles/theme"
import { Container } from "./styles"
import { Trash } from "phosphor-react"
import { useAppTranslate } from "../../../../../../translate/useAppTranslate"

interface AttachedFileProps {
  assetId: string
  attachmentId: string
  name: string
  sizeInBytes: number
  url: string
}

export const AttachedFile = ({
  assetId,
  attachmentId,
  name,
  sizeInBytes,
  url,
}: AttachedFileProps) => {
  const {
    buttons,
    assets: { attachments },
  } = useAppTranslate()

  const [deleteAttachment, mutation] = useDeleteAssetAttachmentMutation()

  const handleDelete = async () => {
    deleteAttachment({
      assetId,
      attachmentId,
    })
      .unwrap()
      .then(() => {})
      .catch(() => {})
  }

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const sizeInMb = (sizeInBytes / 1048576).toFixed(2)

  return (
    <>
      {isModalOpen && (
        <ModalHedro
          onClose={() => setIsModalOpen(false)}
          actionLabel={buttons.delete}
          actionVariant='destructive'
          isActionsDisabled={mutation.isLoading}
          leftButtonLabel={buttons.cancel}
          title={attachments.deleteModalTitle}
          onAction={handleDelete}
          onLeftButton={() => setIsModalOpen(false)}
        >
          <Text color='gray.600' fontSize='md'>
            {attachments.deleteModalMessage}
          </Text>
        </ModalHedro>
      )}

      <Container>
        <FlexContainer
          direction='row'
          justify='space-between'
          gap={4}
          fullWidth
        >
          <Text
            color='info.darker'
            as='a'
            style={{
              textDecoration: "underline",
            }}
            href={url}
            target='_blank'
          >
            {name}
          </Text>
          <Text color='gray.500' nowrap>
            ({`${sizeInMb} mb`})
          </Text>
        </FlexContainer>

        <Trash
          size={24}
          color={theme.palette.gray[800]}
          onClick={() => setIsModalOpen(true)}
        />
      </Container>
    </>
  )
}
