import { HDR_SERVICES_TYPE } from "hdr-process-data"
import { useCallback } from "react"
import { iotApi } from "../../store/api/iot/slice"
import { useAppDispatch } from "../../store/hooks"
import { IoTData } from "../../store/api/iot/entities"
import {
  MessageRaw,
  processApiDataArray,
} from "../../store/features/data/data.utilities"
import { transformDataArrayInPoints } from "../analytic_data/transformDataArrayInPoints"
import { Axis } from "../entities/Axis"
import { ServiceData } from "../../store/features/data/data.interfaces"
import { ChartConfig } from "../../store/api/analytics/entities"
import { FftType } from "../entities/FftType"

export const useAssetData = () => {
  const dispatch = useAppDispatch()

  const fetchIoTData = useCallback(
    async (
      serviceType: HDR_SERVICES_TYPE,
      assetsIds: string[],
      startDate: string,
      endDate: string
    ): Promise<IoTData[]> => {
      const iotData = await dispatch(
        iotApi.endpoints.fetchIotData.initiate({
          startDate: new Date(startDate).toISOString(),
          endDate: new Date(endDate).toISOString(),
          serviceType,
          assetsIds,
        })
      ).unwrap()

      return iotData
    },
    [dispatch]
  )

  const getProcessData = useCallback(
    (
      iotData: MessageRaw[],
      serviceType: HDR_SERVICES_TYPE
    ): {
      rssiList: number[]
      data: ServiceData[]
    } => {
      const processedDataResponse = processApiDataArray({
        data: iotData,
        type: serviceType,
      })

      if (processedDataResponse.isLeft()) {
        console.error(processedDataResponse.value)
        return {
          rssiList: [],
          data: [],
        }
      }

      return processedDataResponse.value
    },
    []
  )

  const getFftType = useCallback((type: HDR_SERVICES_TYPE, chartId: string) => {
    if (type !== HDR_SERVICES_TYPE.fft) return undefined

    const fftType = localStorage.getItem(`FFT-TYPE-${chartId}`)

    if (!fftType) throw Error("FFT type not found")

    return fftType as FftType
  }, [])

  const getPoints = useCallback(
    ({
      data,
      type,
      config,
      chartId,
    }: {
      data: ServiceData[]
      type: HDR_SERVICES_TYPE
      config?: ChartConfig
      chartId: string
    }) => {
      const points = transformDataArrayInPoints({
        axis: config ? (config.toLowerCase() as Axis) : undefined,
        data,
        type,
        fftType: getFftType(type, chartId),
      })

      if (points.isLeft()) throw Error(points.value.message)

      return points
    },
    [getFftType]
  )

  const getStartAndEndDate = useCallback(
    (period: number, serviceType: HDR_SERVICES_TYPE) => {
      if (
        serviceType === HDR_SERVICES_TYPE.fft ||
        serviceType === HDR_SERVICES_TYPE.accRaw
      ) {
        const periodInMilliseconds = 60 * 10 * 1000
        const now = new Date().getTime()

        return {
          endDate: new Date(now).toISOString(),
          startDate: new Date(now - periodInMilliseconds).toISOString(),
        }
      }

      const periodInMilliseconds = period * 1000
      const now = new Date().getTime()

      return {
        endDate: new Date(now).toISOString(),
        startDate: new Date(now - periodInMilliseconds).toISOString(),
      }
    },
    []
  )

  const getProcessedDataFromApi = useCallback(
    async ({
      serviceType,
      assetIds,
      startDate,
      endDate,
    }: {
      serviceType: HDR_SERVICES_TYPE
      assetIds: string[]
      startDate: string
      endDate: string
    }) => {
      const iotData = await fetchIoTData(
        serviceType,
        assetIds,
        startDate,
        endDate
      )

      const processedData = getProcessData(
        iotData.map((data) => {
          return {
            applicationVersion: data.applicationVersion,
            serviceType: data.serviceType,
            protocolVersion: data.protocolVersion,
            raw: data.raw,
            rssi: data.rssi,
            time: Number(data.time),
          }
        }),
        serviceType
      )

      return processedData
    },
    [fetchIoTData, getProcessData]
  )

  return {
    getProcessedDataFromApi,
    getPoints,
    getProcessData,
    getStartAndEndDate,
    getFftType,
  }
}
