export enum EventUserType {
  EQUIPMENT_FAILURE = "EquipmentFailure",
  MAINTENANCE_CORRECTIVE = "MaintenanceCorrective",
  MAINTENANCE_PREVENTIVE = "MaintenancePreventive",
  OPERATIONAL_STOP = "OperationalStop",
  PLANNED_STOP = "PlannedStop",
  INSPECTION = "Inspection",
  OTHER = "Others",
}

export enum EventPlatformType {
  ASSET_CREATED = "AssetCreated",
  ASSET_UPDATED = "AssetUpdated",
  ASSET_DELETED = "AssetDeleted",
  ASSET_MOVED_TO_NEW_PARENT = "AssetMovedToNewParent",
  SENSOR_LINKED_TO_ASSET = "SensorLinkedToAsset",
  SENSOR_UNLINKED_FROM_ASSET = "SensorUnlinkedFromAsset",
}

export type EventAll = EventUserType | EventPlatformType
