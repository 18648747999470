import Plot from "react-plotly.js"
import { useFFT3DChartController } from "../../../controllers/useFFT3DChartController"

import "./style.css"

import { Container } from "./styles"

const AnyPlot = Plot as any;

const Fft3DChart = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { data, title }: any = useFFT3DChartController()
  return (
    <Container>
      <AnyPlot
        className='fft3dchart'
        data={[data]}
        layout={{
          title: title,
          autosize: true,
        }}
        config={{ responsive: true }}
      />
    </Container>
  )
}

export default Fft3DChart
