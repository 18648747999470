import { useRef, useState } from "react"
import { useAppTranslate } from "../../translate/useAppTranslate"
import { toast } from "react-toastify"
import html2canvas from "html2canvas"
import { jsPDF } from "jspdf"

export const useQRCode = () => {
  const { qrCode } = useAppTranslate()

  const [isLoading, setIsLoading] = useState(false)

  const qrRef = useRef<HTMLDivElement>(null)

  const qrCodeHeightInMM = 70
  const qrCodeWidthInMM = 70

  // Convert mm to px
  const height = qrCodeHeightInMM * 3.7795275591
  const width = qrCodeWidthInMM * 3.7795275591

  const handleDownloadQRCode = async (filename: string) => {
    try {
      setIsLoading(true)
      if (!qrRef.current) return

      const elementHeight = qrRef.current.clientHeight * 0.264583
      const elementWidth = qrRef.current.clientWidth * 0.264583

      const canvas = await html2canvas(qrRef.current)
      const imgData = canvas.toDataURL("image/png")

      const pdf = new jsPDF("p", "mm", [elementHeight, elementWidth])

      pdf.addImage(imgData, "PNG", 0, 0, elementWidth, elementHeight)

      pdf.save(`${filename}.pdf`)
    } catch (error) {
      toast.error(qrCode.error)
    } finally {
      setIsLoading(false)
    }
  }

  return {
    qrRef,
    height,
    width,
    isLoading,
    handleDownloadQRCode,
  }
}
