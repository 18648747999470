import Row from "../../../../components/ui/Row"
import { useFetchAssetDetailsQuery } from "../../../../store/store"
import Column from "../../../../components/ui/Column"
import { AssetSchema } from "../utils/schema"
import { AssetForm, AssetFormSkeleton } from "../components/AssetForm"
import { ErrorMessage } from "../../../../components/ui/ErrorMessage"
import { useAppTranslate } from "../../../../translate/useAppTranslate"
import { useConfigureAsset } from "./controllers/useConfigureAsset"

interface ConfigureProps {
  assetId: string
  handleClose: () => void
}

export const ConfigureAsset = ({ assetId, handleClose }: ConfigureProps) => {
  const {
    data: details,
    isLoading,
    isError,
    refetch,
  } = useFetchAssetDetailsQuery(assetId)

  const { assets } = useAppTranslate()

  const { handleSubmit, isDisable } = useConfigureAsset()

  const handleFormSubmit = async (
    data: AssetSchema,
    picture: File | null | undefined
  ) => {
    const result = await handleSubmit(data, assetId, picture)
    if (result === null) return

    handleClose()
  }

  return (
    <>
      <Row>
        <Column md='24' lg='24' xl='24'>
          {details && (
            <AssetForm
              defaultDetails={details}
              isRoot={details.isRoot}
              handleSubmit={handleFormSubmit}
              handleCancel={handleClose}
              isDisabled={isDisable}
            />
          )}
          {isLoading && <AssetFormSkeleton />}
          {isError && (
            <ErrorMessage
              message={assets.details.error}
              handleReload={refetch}
            />
          )}
        </Column>
      </Row>
    </>
  )
}
