import { Text } from "../../../ui/Text"
import { Content, TableWrapper, Header } from "./styles"
import { AssetCard } from "../AssetCard"
import { FlexContainer } from "../../../ui/FlexContainer"
import { useAppTranslate } from "../../../../translate/useAppTranslate"
import { useContext } from "react"
import { AssetsContext, AssetsContextDTO } from "../../context/assetsContext"
import { Checkbox } from "../../../ui/Checkbox"
import { ServiceConfigured } from "../../../../utils/entities/assets/ServiceConfigured"

export interface Asset {
  id: string
  name: string
  path: string
  services: ServiceConfigured[]
}

interface AssetsTableProps {
  hasAxes: boolean
}

export const AssetsTable = ({ hasAxes }: AssetsTableProps) => {
  const {
    components: {
      configureAssets: { table },
    },
  } = useAppTranslate()

  const {
    assetsList,
    handleChangeSelectionCheckbox,
    getSelectionCheckboxState,
  } = useContext(AssetsContext) as AssetsContextDTO

  const SelectionCheckbox = () => {
    const state = getSelectionCheckboxState()

    return (
      <Checkbox
        checked={state === "true"}
        indeterminate={state === "indeterminate"}
        onChange={(e) => handleChangeSelectionCheckbox(e.target.checked)}
      />
    )
  }

  return (
    <Content>
      <Header>
        <Text fontWeight='semibold' color='gray.800'>
          {table.title}
        </Text>

        <SelectionCheckbox />
      </Header>

      <TableWrapper>
        {!assetsList.length && (
          <FlexContainer padding='16px' justify='center' fullHeight>
            <Text fontWeight='medium' color='gray.800'>
              {table.empty}
            </Text>
          </FlexContainer>
        )}

        {!!assetsList.length &&
          assetsList.map((asset) => {
            return <AssetCard key={asset.id} asset={asset} />
          })}
      </TableWrapper>
    </Content>
  )
}
