import { HDR_SERVICES_TYPE } from "hdr-process-data"
import { useAppTranslate } from "../translate/useAppTranslate"
import { FftType } from "./entities/FftType"
import { useCallback } from "react"

export const useHDRService = () => {
  const { services } = useAppTranslate()

  const getServiceName = useCallback(
    (type: HDR_SERVICES_TYPE): string => {
      return services[type].name
    },
    [services]
  )

  const getHDRServiceAcronym = useCallback(
    (type: HDR_SERVICES_TYPE): string => {
      switch (type) {
        case HDR_SERVICES_TYPE.health:
          return "HEALTH"
        case HDR_SERVICES_TYPE.temp:
          return "TEMP"
        case HDR_SERVICES_TYPE.tempMMM:
          return "TEMP_MMM"
        case HDR_SERVICES_TYPE.rms2:
          return "RMS2"
        case HDR_SERVICES_TYPE.rmms:
          return "RMMS"
        case HDR_SERVICES_TYPE.tilt:
          return "TILT"
        case HDR_SERVICES_TYPE.fft:
          return "FFT"
        case HDR_SERVICES_TYPE.accRaw:
          return "ACCRAW"
        case HDR_SERVICES_TYPE.gps:
          return "GPS"
        case HDR_SERVICES_TYPE._4t20:
          return "4T20"
        case HDR_SERVICES_TYPE.ntc:
          return "NTC"
        case HDR_SERVICES_TYPE.pot:
          return "POT"
      }
    },
    []
  )

  const get4a20Unit = (mac?: string): string => {
    if (!mac) return "mA"

    const extraConfig = localStorage.getItem(`${mac} - _4t20 - ExtraConfig`)

    if (!extraConfig) return "mA"

    try {
      const objEctg = JSON.parse(extraConfig)

      if (!objEctg) return "mA"

      return objEctg.unitOfMeasurement
    } catch (error) {
      return "mA"
    }
  }

  const getPotUnit = (mac?: string): string => {
    if (!mac) return "%"

    const extraConfig = localStorage.getItem(`${mac} - pot - ExtraConfig`)

    if (!extraConfig) return "%"

    try {
      const objEctg = JSON.parse(extraConfig)

      if (!objEctg) return "%"

      return objEctg.unitOfMeasurement
    } catch (error) {
      return "%"
    }
  }

  const getServiceUnit = useCallback(
    ({
      type,
      fftType,
      mac,
    }: {
      type: HDR_SERVICES_TYPE
      fftType?: FftType
      mac?: string
    }): string => {
      switch (type) {
        case HDR_SERVICES_TYPE.health:
          return ""
        case HDR_SERVICES_TYPE.temp:
          return "°C"
        case HDR_SERVICES_TYPE.tempMMM:
          return "°C"
        case HDR_SERVICES_TYPE.rms2:
          return "m/s"
        case HDR_SERVICES_TYPE.rmms:
          return "mm/s"
        case HDR_SERVICES_TYPE.tilt:
          return "°"
        case HDR_SERVICES_TYPE.fft: {
          if (fftType === FftType.Acceleration) return "m/s²"

          return "mm/s"
        }
        case HDR_SERVICES_TYPE.accRaw:
          return "m/s²"
        case HDR_SERVICES_TYPE.gps:
          return ""
        case HDR_SERVICES_TYPE._4t20:
          return get4a20Unit(mac)
        case HDR_SERVICES_TYPE.ntc:
          return "°C"
        case HDR_SERVICES_TYPE.pot:
          return getPotUnit(mac)
      }
    },
    []
  )

  const getServiceHelpText = useCallback(
    ({ type }: { type: HDR_SERVICES_TYPE }): string => {
      if (
        type === HDR_SERVICES_TYPE.pot ||
        type === HDR_SERVICES_TYPE._4t20 ||
        type === HDR_SERVICES_TYPE.fft
      ) {
        return `${services[type].name} `
      }

      return `${services[type].name} (${getServiceUnit({ type })})`
    },
    [services, getServiceUnit]
  )

  return {
    getServiceName,
    getHDRServiceAcronym,
    getServiceUnit,
    getServiceHelpText,
  }
}
