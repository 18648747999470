import { useState } from "react"
import { ModalHedro } from "../../../../../../../../components/ModalHedro"
import { EventHistoy } from "../../../../../EventHistory"
import { useEventHistory } from "../../../../../EventHistory/controllers/useEventHistory"
import { zodResolver } from "@hookform/resolvers/zod"
import { eventSchema, EventSchema } from "../../../../../utils/schema"
import { useForm } from "react-hook-form"
import { useAppTranslate } from "../../../../../../../../translate/useAppTranslate"

interface ModalEventProps {
  handleCloseModal: () => void
  onClickDelete?: () => void
  assetId: string
  edit?: boolean
  titleDefault?: string
  descriptionDefault?: string
  typeDefault?: string
  executedAtDefault?: string
  assetName: string
  eventId?: string
  typeModal: "edit" | "create" | "view"
}

export const ModalEvent = ({
  handleCloseModal,
  onClickDelete,
  assetId,
  assetName,
  titleDefault = "",
  descriptionDefault = "",
  typeDefault = "",
  executedAtDefault = "",
  typeModal,
  eventId = "",
}: ModalEventProps) => {
  const { handleCreateEventHistory, handleEditEventHistory, isLoading } =
    useEventHistory()

  const [typeModalEditable, setTypeModalEditable] = useState<"edit" | "view">(
    "view"
  )

  const {
    assets: { history },
  } = useAppTranslate()

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<EventSchema>({
    resolver: zodResolver(eventSchema),
    defaultValues: {
      title: titleDefault,
      description: descriptionDefault,
      eventType: typeDefault,
      executedAt: executedAtDefault,
    },
  })

  const onSubmit = (data: EventSchema) => {
    const eventInfo = {
      title: data.title,
      description: data.description,
      name: assetName,
    }
    const date = new Date(data.executedAt)

    if (typeModal === "create") {
      handleCreateEventHistory({
        assetId: assetId.toString(),
        eventInfo: eventInfo,
        eventType: data.eventType,
        executedAt: date.toISOString(),
      }).then(() => {
        handleCloseModal()
      })
    } else if (typeModal === "edit") {
      handleEditEventHistory({
        assetId: assetId.toString(),
        eventId: eventId,
        eventInfo: eventInfo,
        eventType: data.eventType,
        executedAt: date.toISOString(),
      }).then(() => {
        handleCloseModal()
      })
    }
  }

  const handleOnEventDelete = typeModal === "edit" ? onClickDelete : undefined
  const handleOnClick =
    typeModal === "view" || typeModalEditable === "view"
      ? undefined
      : handleSubmit(onSubmit)

  const handleTitle = () => {
    if (typeModal === "create") {
      return history.createEvent
    } else if (typeModalEditable === "edit") {
      return history.editEvent
    } else {
      return history.eventInformation
    }
  }

  const handleEnableEdit = () => {
    if (typeModalEditable === "view") {
      setTypeModalEditable("edit")
    }
    if (typeModalEditable === "edit") {
      setTypeModalEditable("view")
    }
  }

  return (
    <ModalHedro
      onClose={() => handleCloseModal()}
      actionLabel={history.complete}
      title={handleTitle()}
      onAction={typeModal === "create" ? handleSubmit(onSubmit) : handleOnClick}
      onClickDelete={handleOnEventDelete}
      onClickEdit={
        typeModal === "create" || typeModal === "view"
          ? undefined
          : handleEnableEdit
      }
      isActionsDisabled={isLoading}
    >
      <EventHistoy
        defaultTitle={titleDefault}
        defaultDescription={descriptionDefault}
        defaultType={typeDefault}
        defaultExecutedAt={executedAtDefault}
        typeModal={typeModal === "create" ? typeModal : typeModalEditable}
        register={register}
        errors={errors}
      />
    </ModalHedro>
  )
}
