import { IconComponent } from "../../../ui/Icon"
import {
  CustomLink,
  MenuDrop,
  MenuDropContent,
  NavTitle,
  SArrowContainer,
  SArrowIcon,
  SDropdown,
} from "../../styles/Navbar.styled"
import { ReactNode, useState } from "react"
import { PermissionsProteu } from "../../../../store/features/user/user.interfaces"
import { ProtectedComponent } from "../../../../utils/authentication/ProtectedComponent"

type TreeList = {
  name: string
  href: string
  permission: PermissionsProteu | PermissionsProteu[]
}

export interface DropdownMenuItens {
  page: string
  href: string | null
  icon: ReactNode
  permissions: PermissionsProteu | PermissionsProteu[]
  tree?: Array<TreeList>
}

export const DropdownMenu = ({
  page,
  href,
  icon,
  permissions,
  tree,
}: DropdownMenuItens) => {
  const [dropOpen, setDropOpen] = useState(false)

  const openDropdown = () => {
    setDropOpen(!dropOpen)
  }

  return (
    <>
      {href && (
        <ProtectedComponent permissions={permissions}>
          <li>
            <CustomLink
              to={href}
              className={(isActive) =>
                "nav-link" + (!isActive ? " unselected" : "")
              }
            >
              <IconComponent className='menu-icon'>{icon}</IconComponent>
              <NavTitle>{page}</NavTitle>
            </CustomLink>
          </li>
        </ProtectedComponent>
      )}
      {!href && (
        <MenuDrop onClick={openDropdown}>
          <ProtectedComponent permissions={permissions}>
            <div className='menu-content'>
              <MenuDropContent>
                <IconComponent className='menu-icon'>{icon}</IconComponent>
                <NavTitle>{page}</NavTitle>
              </MenuDropContent>
              <SArrowContainer $isOpen={dropOpen}>
                <SArrowIcon />
              </SArrowContainer>
            </div>

            <SDropdown $open={dropOpen}>
              {tree?.map((branch, index) => (
                <a key={index} href={branch.href}>
                  <ProtectedComponent permissions={branch.permission}>
                    {branch.name}
                  </ProtectedComponent>
                </a>
              ))}
            </SDropdown>
          </ProtectedComponent>
        </MenuDrop>
      )}
    </>
  )
}
