import styled from "styled-components"
import { media } from "../../../../../../utils/media-queries"

export const Container = styled.div`
  padding: 16px 0px 16px 10px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: 100%;
  margin: 0 12px;

  @media ${media.large} {
    margin: 0;
  }
`

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`
