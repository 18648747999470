import styled from "styled-components"

export const Container = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`
export const TooltipWrapper = styled.div`
  position: relative;
`
export const Tooltip = styled.div`
  position: absolute;
  top: 22px;
  right: 0;
  background-color: ${({ theme }) => theme.palette.gray[50]};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.palette.gray[400]};

  padding: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
`
export const CheckboxContainer = styled.div`
  padding-left: 10px;
  width: 140px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const CaretComponent = styled.div`
  display: flex;
  align-items: center;
  width: 25px;
`
