import styled from "styled-components"
import { media } from "../../../../../../../utils/media-queries"

export const Container = styled.div`
  padding: 16px 5px 16px 10px;
  overflow-y: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  @media ${media.large} {
    margin: 0;
  }
`
