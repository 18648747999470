import { SortAscending } from "phosphor-react"
import { CheckboxContainer, Container, Tooltip, TooltipWrapper } from "./styles"
import { FlexContainer } from "../../../../../../../../components/ui/FlexContainer"
import { Checkbox } from "../../../../../../../../components/ui/Checkbox"
import { Text } from "../../../../../../../../components/ui/Text"
import { useState } from "react"
import { OrderingEvent } from "../../../../../../../../store/api/assets/entities"

interface HistoryOrdeningProps {
  handleChangeOrdering: (ordering: OrderingEvent) => void
}

export const HistoryOrdening = ({
  handleChangeOrdering,
}: HistoryOrdeningProps) => {
  const [showAxes, setShowAxes] = useState(false)

  const [orderBy, setOrderBy] = useState<OrderingEvent>("Created (ASC)")

  const options: OrderingEvent[] = [
    "Created (DESC)",
    "Created (ASC)",
    "Executed (DESC)",
    "Executed (ASC)",
  ]

  const handleCheckboxChange = (option: OrderingEvent) => {
    setOrderBy(option)
    handleChangeOrdering(option)
  }

  return (
    <Container>
      <SortAscending size={24} onClick={() => setShowAxes(!showAxes)} />
      {showAxes && (
        <TooltipWrapper>
          <Tooltip>
            <FlexContainer gap={8} align='start'>
              <CheckboxContainer>
                {options.map((option) => (
                  <FlexContainer
                    key={option}
                    direction='row'
                    gap={8}
                    justify='start'
                  >
                    <Checkbox
                      checked={orderBy === option}
                      onChange={() => handleCheckboxChange(option)}
                      type='radio'
                    />
                    <Text>{option}</Text>
                  </FlexContainer>
                ))}
              </CheckboxContainer>
            </FlexContainer>
          </Tooltip>
        </TooltipWrapper>
      )}
    </Container>
  )
}
