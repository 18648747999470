import { memo } from "react"
import { ModalHedro } from "../../../../../components/ModalHedro"
import { useAppTranslate } from "../../../../../translate/useAppTranslate"
import { LinkSensorForm } from "../LinkSensorForm"
import { Text } from "../../../../../components/ui/Text"
import { useConfigureSensor } from "../../List/controllers/useConfigureSensor"

interface ModalConfigureSensorProps {
  handleCloseModal: () => void
  assetId: string
}

export const ModalConfigureSensor = memo(
  ({ handleCloseModal, assetId }: ModalConfigureSensorProps) => {
    const { assets } = useAppTranslate()

    const {
      handleSubmit,
      register,
      handleSelectSensor,
      isDisable,
      isEmpty,
      errors,
    } = useConfigureSensor({
      assetId,
      handleCloseModal,
    })

    return (
      <ModalHedro
        onClose={handleCloseModal}
        onAction={handleSubmit}
        actionLabel={assets.sensors.associateSensor}
        title={assets.sensors.addModalTitle}
        isActionsDisabled={isDisable}
      >
        <LinkSensorForm
          onCheck={(id) => handleSelectSensor(Number(id))}
          dateInputProps={register("linkedAt")}
          dateInputError={errors.linkedAt && assets.form.errors.required}
          dateInputIsEmpty={isEmpty}
        />

        <Text color='error.main' fontSize='xs'>
          {errors.sensorId && assets.form.errors.required}
        </Text>
      </ModalHedro>
    )
  }
)

ModalConfigureSensor.displayName = "ModalConfigureSensor"
