import { NewHeader } from "../../../../components/NewHeader"
import Column from "../../../../components/ui/Column"
import { TabList } from "../../../../components/ui/TabList"
import { Tabs } from "../../../../components/ui/Tabs"
import { TabPanels } from "../../../../components/ui/TabPanels"
import { TabPanel } from "../../../../components/ui/TabPanel"
import { Tab } from "../../../../components/ui/Tab"
import {
  AssetsContainer,
  Content,
  Wrapper,
  MessageContainer,
  HistoryComponent,
  ColumnsWrapper,
} from "./styles"
import { AssetDetails, AssetDetailsSkeleton } from "./components/Details"
import { HistoryCardSkeleton } from "./components/Skeleton/HistoryCard"
import { useAppTranslate } from "../../../../translate/useAppTranslate"
import { CollapseTree } from "./components/CollapseTree"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useFetchAssetsQuery } from "../../../../store/store"
import { HistoryCard } from "./components/HistoryCard"

export const AssetsWrapper = () => {
  const { assets, lateralMenu } = useAppTranslate()

  const navigate = useNavigate()

  const page = {
    title: lateralMenu.routes.assets.title,
  }

  const [open, setOpen] = useState(true)

  const [assetDetail, setAssetDetails] = useState<string | undefined>()

  const { id } = useParams<{ id: string }>()

  const {
    data: listAssets,
    isLoading,
    isError,
  } = useFetchAssetsQuery({ services: false })

  useEffect(() => {
    if (!listAssets) return
    const assetsIds = Object.keys(listAssets)
    if (assetsIds.length === 0) return

    if (!id) {
      if (assetsIds.length === 0) return

      setAssetDetails(listAssets[assetsIds[0]].data.id)
      return
    }

    if (id && listAssets[id]) {
      setAssetDetails(listAssets[id].data.id)
      return
    }

    navigate(`/assets`)
  }, [listAssets, id, navigate])

  const handleChangeAssetDetails = (id: string) => {
    setAssetDetails(id)
    navigate(`/assets/${id}`)
  }

  return (
    <AssetsContainer>
      <NewHeader page={page} />

      <ColumnsWrapper>
        <Column sm='19' md='24' lg='19' xl='19'>
          <Wrapper>
            <CollapseTree
              onCollapse={() => setOpen((prev) => !prev)}
              onChangeAssetDetails={handleChangeAssetDetails}
              open={open}
            />

            <Content>
              <Tabs>
                <TabList>
                  <Tab>{assets.details.title}</Tab>
                </TabList>

                <TabPanels>
                  <TabPanel>
                    {assetDetail && <AssetDetails assetId={assetDetail} />}

                    {isLoading && <AssetDetailsSkeleton />}

                    {isError && (
                      <MessageContainer>
                        {assets.details.error}
                      </MessageContainer>
                    )}
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Content>
          </Wrapper>
        </Column>

        <Column sm='5' md='24' lg='5' xl='5'>
          <HistoryComponent>
            {assetDetail && <HistoryCard assetId={assetDetail} />}

            {isLoading && <HistoryCardSkeleton />}

            {isError && (
              <MessageContainer>{assets.history.error}</MessageContainer>
            )}
          </HistoryComponent>
        </Column>
      </ColumnsWrapper>
    </AssetsContainer>
  )
}
