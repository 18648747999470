import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react"
import { getAccessToken } from "../../utils/getAccessToken"
import { HDR_SERVICES_TYPE } from "hdr-process-data"

export const thorBaseQuery = () =>
  fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_THOR_URL,
    prepareHeaders: (headers) => {
      const token = getAccessToken()

      if (token) headers.set("Authorization", `Bearer ${token}`)

      return headers
    },
  })

export const servicesTypes = [
  HDR_SERVICES_TYPE.health,
  HDR_SERVICES_TYPE.temp,
  HDR_SERVICES_TYPE.rms2,
  HDR_SERVICES_TYPE.rmms,
  HDR_SERVICES_TYPE.tilt,
  HDR_SERVICES_TYPE.fft,
  HDR_SERVICES_TYPE.accRaw,
  HDR_SERVICES_TYPE._4t20,
  HDR_SERVICES_TYPE.ntc,
  HDR_SERVICES_TYPE.pot,
]
