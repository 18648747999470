import { Container } from "./styles"
import { Text } from "../../../../../components/ui/Text"
import { useAppTranslate } from "../../../../../translate/useAppTranslate"

interface AssetTypeProps {
  typeName: string
  createdAt: string
}

export const AssetType = ({ typeName, createdAt }: AssetTypeProps) => {
  const { assets } = useAppTranslate()

  return (
    <Container>
      <Text>{`${assets.details.type}: ${typeName}`}</Text>
      <Text>{`${assets.details.createdAt}: ${createdAt}`}</Text>
    </Container>
  )
}
