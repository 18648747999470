import React from "react"
import { Card } from "../../../../../../../components/Card"
import { Divider } from "../../../../../../../components/ui/Divider"
import { HistoryHeader } from "../../HistoryCard/components/HistoryHeader"
import { Container } from "./styles"
import { HistoryFooter } from "../../HistoryCard/components/HistoryFooter"

export const HistoryCardSkeleton = () => {
  const skeletonItems = 5

  return (
    <Container>
      <HistoryHeader
        handleOpenCreateEventModal={() => {}}
        handleChangeOrdering={() => {}}
        handleChangeFilter={() => {}}
        handleChangeRecursive={() => {}}
        filter=''
        isRecursive={false}
      />
      {Array.from({ length: skeletonItems }, (_, index) => (
        <React.Fragment key={index}>
          <Card title='' type='notification' skeleton assetId={0} />
          <Divider />
        </React.Fragment>
      ))}

      <HistoryFooter
        total={5}
        itemsPerPage={5}
        page={1}
        onChangePage={() => {}}
      />
    </Container>
  )
}
