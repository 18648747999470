import { useCallback } from "react"
import { useDeleteAnalyticMutation } from "../../../store/store"

export const useDeleteChart = () => {
  const [deleteChart, mutation] = useDeleteAnalyticMutation()

  const handleDeleteChart = useCallback(
    async (chartId: string, dashboardId: number) => {
      await deleteChart({
        analyticId: chartId,
        dashboardId,
      }).unwrap()
    },
    [deleteChart]
  )

  return {
    handleDeleteChart,
    isDeleteDisabled: mutation.isLoading,
  }
}
