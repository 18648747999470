import { ReactNode, useEffect, useMemo, useRef, useState } from "react"
import {
  Tree,
  TreeItem,
  TreeItemRenderContext,
  TreeRef,
  UncontrolledTreeEnvironment,
} from "react-complex-tree"
import { useAppTranslate } from "../../../translate/useAppTranslate"
import { Container, InputContainer, TreeContainer } from "./styles"
import { Text } from "../Text"
import { Input } from "../Input"
import { MagnifyingGlass } from "phosphor-react"
import { CustomDataProvider } from "./CustomDataProvider"
import { TreeContentController } from "./treeContentController"
import { FlexContainer } from "../FlexContainer"
import { useFetchAssetsQuery } from "../../../store/store"
import { TreeItemRenderer } from "../TreeItemRenderer"
import { useParams } from "react-router-dom"
import { AssetData } from "../../../utils/entities/assets/AssetTreeData"

export interface RenderItemProps<T> {
  item: TreeItem<T>
  depth: number
  children: ReactNode | null
  title: ReactNode
  arrow: ReactNode
  context: TreeItemRenderContext<"expandedItems">
}

interface AssetsTreeProps {
  services?: boolean
  showAddIcon?: (item: TreeItem<AssetData>) => boolean
  onClick?: (item: TreeItem<AssetData>) => void
  onClickAddIcon: (item: TreeItem<AssetData>) => void
}

export const AssetsTree = ({
  services = false,
  showAddIcon = () => true,
  onClick,
  onClickAddIcon,
}: AssetsTreeProps) => {
  const { data, isError } = useFetchAssetsQuery({ services })

  const dataProvider = useMemo(() => {
    if (!data) {
      return new CustomDataProvider({})
    } else {
      return new CustomDataProvider({ ...data })
    }
  }, [data])

  const tree = useRef<TreeRef>(null)

  const { id } = useParams()

  const [hasRendered, setHasRendered] = useState<boolean>(false)

  const [search, setSearch] = useState("")

  const { find, findById } = TreeContentController(dataProvider, search, tree)
  const { assets } = useAppTranslate()

  if (!hasRendered) {
    if (!id) {
      setHasRendered(true)
    } else {
      findById(id).then((path) => {
        setHasRendered(path !== null)
      })
    }
  }

  useEffect(() => {
    setHasRendered(true)

    if (id) {
      findById(id).then((path) => {
        setHasRendered(path !== null)
      })
    }
  }, [findById, hasRendered, id, tree])

  const renderItem = ({ item, ...props }: RenderItemProps<AssetData>) => {
    return (
      <TreeItemRenderer
        {...props}
        onSelectItem={onClick}
        showAddIcon={showAddIcon(item)}
        onClickAddIcon={onClickAddIcon}
        item={item}
      />
    )
  }

  if (isError) {
    return (
      <FlexContainer
        fullHeight
        fullWidth
        align='center'
        justify='start'
        padding='0 0 12px 0'
      >
        <Text>{assets.tree.error}</Text>
      </FlexContainer>
    )
  }

  return (
    <>
      <InputContainer>
        <Input
          placeholder={assets.tree.search}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          icon={<MagnifyingGlass size={20} weight='bold' />}
          onClickIcon={find}
        />
      </InputContainer>

      <Container key={JSON.stringify(data)}>
        <UncontrolledTreeEnvironment
          canDragAndDrop
          canDropOnFolder
          canReorderItems
          dataProvider={dataProvider}
          getItemTitle={(item) => item.data.name}
          viewState={{ "tree-1": { expandedItems: ["root"] } }}
          renderDepthOffset={20}
          renderItemTitle={({ title }) => <span>{title}</span>}
          renderItem={renderItem}
          renderTreeContainer={({ children, containerProps }) => (
            <TreeContainer {...containerProps}>{children}</TreeContainer>
          )}
          renderItemsContainer={({ children, containerProps }) => (
            <ul {...containerProps}>{children}</ul>
          )}
        >
          <Tree
            treeId='tree-1'
            rootItem='master'
            treeLabel='Tree Example'
            ref={tree}
          />
        </UncontrolledTreeEnvironment>
      </Container>
    </>
  )
}
