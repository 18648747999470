import styled from "styled-components"
import { media } from "../../../../../utils/media-queries"

export const AssetInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  padding: 0px 0 12px 0;

  @media ${media.small} {
    flex-direction: row;
  }
`

export const BasicInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;

  @media ${media.small} {
    flex-direction: row;
  }
`

export const InputWrapper = styled.div`
  width: 100%;

  @media ${media.small} {
    max-width: 300px;
  }
`

export const Content = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
`

export const PropertiesContainer = styled.div`
  padding: 0px 0;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
`

export const PropertiesMessage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  color: ${(props) => props.theme.palette.gray[500]};
`

export const PropertiesFields = styled.div`
  text-transform: capitalize;

  display: grid;
  align-items: start;
  grid-template-columns: 1fr;

  gap: 12px;

  @media ${media.medium} {
    grid-template-columns: repeat(2, minmax(300px, auto));
  }

  @media ${media.large} {
    grid-template-columns: repeat(4, minmax(auto, auto));
  }
`
