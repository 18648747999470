import { ServiceConfigured } from "../../../../utils/entities/assets/ServiceConfigured"

export interface AssetDetails {
  id: string
  isRoot: boolean
  name: string
  assetPicture: string | null
  assetType: AssetType
  createdAt: string
  updatedAt: string
  propertyValues: AssetProperties
  linkedSensor?: LinkedSensor
  description: string
}

export interface LinkedSensor {
  id: number
  name: string
  serialNumber: string
  linkedAt: number
  services: ServiceConfigured[]
}

export interface AssetProperties {
  [key: string]: string
}

export interface AssetType {
  id: number
  name: string
  description: string
  properties: Record<string, Property>
}

export interface Property {
  placeholder: {
    en: string
    es: string
    "pt-br": string
  }
  position: number
  required: boolean
  title: {
    en: string
    es: string
    "pt-br": string
  }
  type: string
  values?: string[]
  tags: {
    en: string[]
    es: string[]
    "pt-br": string[]
  }
}

export const getPropertyLabel = (property: Property) => {
  const language = navigator.language.split("-")[0]

  switch (language) {
    case "en":
      return property.title["en"]
    case "es":
      return property.title["es"]
    case "pt":
      return property.title["pt-br"]
    default:
      return property.title["pt-br"]
  }
}

export const getPropertyPlaceholder = (property: Property) => {
  const language = navigator.language.split("-")[0]

  switch (language) {
    case "en":
      return property.placeholder["en"]
    case "es":
      return property.placeholder["es"]
    case "pt":
      return property.placeholder["pt-br"]
    default:
      return property.placeholder["pt-br"]
  }
}
