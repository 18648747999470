import { MHeadar } from "../../styles"
import { IconButton } from "../../../ui/IconButton"
import { PencilSimple, Trash, X } from "phosphor-react"
import theme from "../../../../styles/theme"
import { Buttons } from "./styles"

interface IModalHeader {
  onClose: () => void
  title: string
  subtitle?: string
  onClickDelete?: () => void
  onClickEdit?: () => void
}

export const ModalHeader = ({
  onClose,
  title,
  subtitle,
  onClickDelete,
  onClickEdit,
}: IModalHeader) => {
  const handleClickDelete = () => {
    onClickDelete && onClickDelete()
    onClose()
  }

  return (
    <MHeadar>
      <div>
        <h1>{title}</h1>
        <h2>{subtitle}</h2>
      </div>
      <Buttons>
        {onClickDelete && (
          <IconButton
            variant='secondary'
            icon={<Trash size={20} color={theme.palette.error.main} />}
            onClick={handleClickDelete}
          />
        )}

        {onClickEdit && (
          <IconButton
            variant='secondary'
            icon={<PencilSimple size={20} />}
            onClick={onClickEdit}
          />
        )}

        <IconButton
          variant='secondary'
          icon={<X size={20} />}
          onClick={onClose}
        />
      </Buttons>
    </MHeadar>
  )
}
