import styled from "styled-components"

export const Container = styled.div`
  padding-bottom: 16px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 99;
`
export const ActionContainer = styled.div`
  display: flex;
  gap: 10px;
`
export const ComponentPlus = styled.div`
  cursor: pointer;
  display: flex;
`
