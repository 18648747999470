import { useMemo } from "react"
import { useDeleteAssetMutation } from "../../../../../store/store"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { useAppTranslate } from "../../../../../translate/useAppTranslate"

export const useDeleteAsset = () => {
  const navigate = useNavigate()
  const { assets } = useAppTranslate()

  const [deleteAsset, mutationDelete] = useDeleteAssetMutation()

  const isDisable = useMemo(
    () => mutationDelete.isLoading,
    [mutationDelete.isLoading]
  )

  const handleDelete = async (assetId: string) => {
    try {
      const { deletedAssetSourceId } = await deleteAsset(assetId).unwrap()
      navigate(`/assets/${deletedAssetSourceId}`)
      toast.success(assets.form.toastSucessDelete)
    } catch (e) {
      console.error(e)
    }
  }

  return {
    handleDelete,
    isDisable,
  }
}
