import { forwardRef, useImperativeHandle, memo } from "react"
import { HighchartsReact } from "highcharts-react-official"
import { useChartController } from "./controller/useChartController"

import Boost from "highcharts/modules/boost"
import Highcharts from "highcharts"

import { ChartData } from "./entities/ChartData"
import { FftType } from "../../utils/entities/FftType"
import { ChartRef } from "../NewChart"

require("highcharts/modules/exporting")(Highcharts)
require("highcharts/modules/export-data")(Highcharts)
require("highcharts/modules/no-data-to-display")(Highcharts)
require("highcharts/modules/full-screen")(Highcharts)

Boost(Highcharts)

interface ChartProps {
  chart: ChartData
  realtime: boolean
  fftType?: FftType
  companyId: number
}

const ChartComponent = forwardRef<ChartRef, ChartProps>((props, ref) => {
  const { chartRef, chartOptions } = useChartController(props)

  useImperativeHandle(ref, () => ({
    openFullscreen: () => {
      if (!chartRef.current) return

      chartRef.current.chart.fullscreen.open()
    },
    printChart: () => {
      if (!chartRef.current) return

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const chart = chartRef.current.chart as any

      chart.print()
    },
    downloadCSV: () => {
      if (!chartRef.current) return

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const chart = chartRef.current.chart as any

      chart.downloadCSV()
    },
    downloadXLS: () => {
      if (!chartRef.current) return

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const chart = chartRef.current.chart as any

      chart.downloadXLS()
    },
    refetch: () => {},
  }))

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={chartOptions()}
      ref={chartRef}
    />
  )
})

ChartComponent.displayName = "Chart"

export const Chart = memo(ChartComponent)
