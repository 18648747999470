import { Container, Header, Content } from "./styles"
import { Title } from "../../../../../components/Title"
import { useAppTranslate } from "../../../../../translate/useAppTranslate"
import { Text } from "../../../../../components/ui/Text"
import { FlexContainer } from "../../../../../components/ui/FlexContainer"
import {
  AssetProperties as AssetPropertiesDTO,
  AssetType,
  getPropertyLabel,
  Property,
} from "../../entities/AssetDetails"
import { Input } from "../../../../../components/ui/Input"

interface AssetPropertiesProps {
  assetType: AssetType
  properties: AssetPropertiesDTO
}

export const AssetProperties = ({
  assetType,
  properties,
}: AssetPropertiesProps) => {
  const { assets } = useAppTranslate()

  const propertyNames = Object.keys(assetType.properties)

  const sortedProperties = Object.entries(assetType.properties).sort(
    ([key1, p1], [key, p2]) => (p1.position > p2.position ? 1 : -1)
  )

  const getValue = (key: string, property: Property): string => {
    if (property.type !== "select") return properties[key]

    const index = property.values?.findIndex(
      (value) => value === properties[key]
    )

    if (index === undefined || index === -1) {
      console.error("Property value not found")
      return ""
    }

    const language = navigator.language.split("-")[0]

    switch (language) {
      case "en":
        return property.tags["en"][index]
      case "es":
        return property.tags["es"][index]
      case "pt":
        return property.tags["pt-br"][index]
      default:
        return ""
    }
  }

  return (
    <Container>
      <Header>
        <Title label={assets.details.properties} size='sm' weight='medium' />
      </Header>

      {propertyNames.length ? (
        <Content>
          {sortedProperties.map(([key, value]) => (
            <Input
              key={key}
              label={getPropertyLabel(value)}
              value={getValue(key, value)}
              readOnly
            />
          ))}
        </Content>
      ) : (
        <FlexContainer justify='center' fullWidth>
          <Text fontSize='sm' color='gray.500' fontWeight='regular'>
            {assets.form.propertiesEmpty}
          </Text>
        </FlexContainer>
      )}
    </Container>
  )
}
