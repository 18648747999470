import {
  Control,
  FieldError,
  FieldErrorsImpl,
  Merge,
  useFieldArray,
  useForm,
  UseFormRegister,
  useWatch,
} from "react-hook-form"
import { Input } from "../../../../../components/ui/Input"
import {
  assetSchema,
  AssetSchema,
  SCHEMA_ERRORS,
  getDefaultValues,
} from "../../utils/schema"
import {
  BasicInfo,
  AssetInfo,
  Content,
  PropertiesMessage,
  PropertiesFields,
  InputContainer,
  InputWrapper,
} from "./styles"
import {
  HTMLInputTypeAttribute,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import { Divider } from "../../../../../components/ui/Divider"
import { TextArea } from "../../../../../components/ui/TextArea"
import { AssetFormSkeleton } from "./AssetForm.skeleton"
import { useAppTranslate } from "../../../../../translate/useAppTranslate"
import { Text } from "../../../../../components/ui/Text"
import { useFetchAssetTypesQuery } from "../../../../../store/store"
import { InputSelect } from "../../../../../components/ui/InputSelect"
import { LinkSensorForm } from "../LinkSensorForm"
import { FlexContainer } from "../../../../../components/ui/FlexContainer"
import { zodResolver } from "@hookform/resolvers/zod"
import { Button } from "../../../../../components/ui/Button"
import { ImageContainer } from "../ImageContainer"
import {
  AssetDetails,
  AssetType,
  getPropertyLabel,
  getPropertyPlaceholder,
  Property,
} from "../../entities/AssetDetails"

interface AssetFormProps {
  defaultDetails?: AssetDetails
  isRoot?: boolean
  isDisabled: boolean
  isCreating?: boolean
  handleSubmit: (
    data: AssetSchema,
    picture: File | null | undefined
  ) => Promise<void>
  handleCancel: () => void
}

const AssetForm = ({
  defaultDetails,
  isRoot = false,
  isDisabled,
  isCreating = false,
  handleSubmit,
  handleCancel,
}: AssetFormProps) => {
  const { assets, buttons } = useAppTranslate()

  const [picture, setPicture] = useState<File | null | undefined>(undefined)

  const form = useForm<AssetSchema>({
    resolver: zodResolver(assetSchema),
  })

  useEffect(() => {
    if (!defaultDetails) return
    const value = getDefaultValues(defaultDetails)
    form.reset(value)
  }, [defaultDetails, form])

  const { data: types, isLoading, isError } = useFetchAssetTypesQuery()

  const getErrorMessage = (message: string | undefined): string | undefined => {
    if (!message) return undefined

    switch (message) {
      case SCHEMA_ERRORS.REQUIRED:
        return assets.form.errors.required
      default:
        return assets.form.errors.default
    }
  }

  const {
    control,
    register,
    formState: { errors },
    setFocus,
  } = form

  const date = useWatch({
    control,
    name: "linkedAt",
  })

  const handleSelectSensor = (id: number) => {
    form.setValue("sensorId", id)
  }

  const handleChangePicture = (file: File | null) => {
    if (file) {
      setPicture(file)
      return
    }

    if (file === null && defaultDetails?.assetPicture) {
      setPicture(null)
      return
    }

    setPicture(undefined)
  }

  const options = useMemo(() => {
    if (!types) return []

    return types.map((type) => type.id)
  }, [types])

  const renderLabel = (type: number): string => {
    const assetType = types?.find((item) => item.id === type)

    if (!assetType) {
      console.error("Error rendering property label!")
      return ""
    }

    return assetType.name
  }

  const handleFocus = useCallback(() => {
    setFocus("type")
  }, [setFocus])

  if (isLoading) return <AssetFormSkeleton />

  if (!types || isError)
    return (
      <FlexContainer gap={12}>
        <Text>{assets.form.errors.types}</Text>
        <Button variant='secondary' onClick={handleCancel}>
          {buttons.back}
        </Button>
      </FlexContainer>
    )

  return (
    <>
      <AssetInfo>
        <ImageContainer
          src={defaultDetails?.assetPicture ?? undefined}
          editable
          onChange={handleChangePicture}
        />

        <BasicInfo>
          <Text fontSize='md' fontWeight='bold'>
            {assets.form.titles.informations}
          </Text>
          <InputContainer>
            <InputWrapper>
              <Input
                label={assets.form.name}
                placeholder={assets.form.namePlaceholder}
                error={getErrorMessage(errors.name?.message)}
                {...register("name")}
              />
            </InputWrapper>

            {!isRoot && (
              <InputWrapper>
                <InputSelect
                  label={assets.details.type}
                  placeholder={assets.form.addTypePlaceholder}
                  options={options}
                  error={getErrorMessage(errors.type?.message)}
                  renderLabel={(option) => renderLabel(Number(option))}
                  {...register("type")}
                />
              </InputWrapper>
            )}
          </InputContainer>
        </BasicInfo>
      </AssetInfo>

      <TextArea
        label={assets.details.description}
        rows={10}
        placeholder={assets.form.descriptionPlaceholder}
        error={getErrorMessage(errors.description?.message)}
        {...register("description")}
      />

      <Divider />

      {types && (
        <Properties
          register={register}
          control={control}
          types={types}
          defaultDetails={defaultDetails!}
          errors={errors.properties}
          onFocus={handleFocus}
        />
      )}

      <Divider />

      {isCreating && (
        <Content>
          <Text fontSize='md' fontWeight='bold'>
            Sensor
          </Text>

          <LinkSensorForm
            onCheck={(id) => handleSelectSensor(Number(id))}
            dateInputProps={register("linkedAt")}
            dateInputError={errors.linkedAt && assets.form.errors.required}
            dateInputIsEmpty={!date}
          />
          <Text color='error.main' fontSize='xs'>
            {errors.sensorId && assets.form.errors.required}
          </Text>
        </Content>
      )}

      <FlexContainer direction='row' justify='space-between' fullWidth>
        <Button variant='secondary' onClick={handleCancel}>
          {buttons.cancel}
        </Button>

        <Button
          variant='primary'
          onClick={form.handleSubmit((data) => {
            handleSubmit(data, picture)
          })}
          disabled={isLoading || isDisabled}
        >
          {assets.form.saveChanges}
        </Button>
      </FlexContainer>
    </>
  )
}

interface PropertiesProps {
  register: UseFormRegister<AssetSchema>
  control: Control<AssetSchema>
  types: AssetType[]
  defaultDetails: AssetDetails
  errors?:
    | Merge<
        FieldError,
        (
          | Merge<
              FieldError,
              FieldErrorsImpl<{
                value: string
                required: boolean
                property: string
              }>
            >
          | undefined
        )[]
      >
    | undefined
  onFocus: () => void
}

export const Properties = ({
  register,
  control,
  types,
  defaultDetails,
  errors,
  onFocus,
}: PropertiesProps) => {
  const { assets } = useAppTranslate()

  const type = useWatch({
    control,
    name: "type",
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: "properties",
  })

  useEffect(() => {
    onFocus()
  }, [fields, onFocus])

  useEffect(() => {
    if (!type) return
    if (!types) return

    remove()

    const assetType = types.find((item) => item.id === Number(type))

    if (assetType?.id === defaultDetails?.assetType.id) {
      const properties = Object.entries(assetType.properties).sort(
        ([k1, p1], [k2, p2]) => (p1.position > p2.position ? 1 : -1)
      )

      properties.forEach((property) => {
        append({
          required: property[1].required,
          property: property[0],
          value: defaultDetails.propertyValues[property[0]],
        })
      })

      return
    }

    if (!assetType) return

    const properties = Object.entries(assetType.properties).sort(
      ([k1, p1], [k2, p2]) => (p1.position > p2.position ? 1 : -1)
    )

    for (let i = 0; i < properties.length; i++) {
      append({
        required: properties[i][1].required,
        property: properties[i][0],
        value: "",
      })
    }
  }, [remove, append, type, types, defaultDetails])

  const getErrorMessage = (message: string | undefined): string | undefined => {
    if (!message) return undefined

    switch (message) {
      case SCHEMA_ERRORS.REQUIRED:
        return assets.form.errors.required
      default:
        return assets.form.errors.default
    }
  }

  const getAssetProperty = (property: string): Property | null => {
    const assetType = types?.find((item) => item.id === Number(type))

    if (!assetType) return null

    const assetProperty = assetType.properties[property]

    if (!assetProperty) return null

    return assetProperty
  }

  const getType = (assetProperty: Property): HTMLInputTypeAttribute => {
    return assetProperty ? assetProperty.type : "text"
  }

  const getTag = (assetProperty: Property, index?: number): string => {
    if (index === undefined) return ""

    const language = navigator.language.split("-")[0]

    switch (language) {
      case "en":
        return assetProperty.tags["en"][index]
      case "es":
        return assetProperty.tags["es"][index]
      case "pt":
        return assetProperty.tags["pt-br"][index]
      default:
        return ""
    }
  }

  return (
    <Content>
      <Text fontSize='md' fontWeight='bold'>
        {assets.form.titles.properties}
      </Text>

      {!type && (
        <PropertiesMessage>
          <Text fontSize='sm' fontWeight='regular'>
            {assets.form.propertiesPlaceholder}
          </Text>
        </PropertiesMessage>
      )}

      {fields.length === 0 && type && (
        <PropertiesMessage>
          <Text fontSize='sm' fontWeight='regular'>
            {assets.form.propertiesEmpty}
          </Text>
        </PropertiesMessage>
      )}

      {fields.length > 0 && (
        <PropertiesFields>
          {fields.map((field, index) => {
            const assetProperty = getAssetProperty(field.property)

            if (!assetProperty) return null

            return getType(assetProperty) === "select" ? (
              <InputSelect
                key={field.id}
                label={getPropertyLabel(assetProperty)}
                placeholder={getPropertyPlaceholder(assetProperty)}
                options={assetProperty.values ?? []}
                renderLabel={(option, index) => getTag(assetProperty, index)}
                error={getErrorMessage(errors?.[index]?.value?.message)}
                {...register(`properties.${index}.value`)}
              />
            ) : (
              <Input
                key={field.id}
                label={getPropertyLabel(assetProperty)}
                placeholder={getPropertyPlaceholder(assetProperty)}
                type={getType(assetProperty)}
                error={getErrorMessage(errors?.[index]?.value?.message)}
                {...register(`properties.${index}.value`)}
              />
            )
          })}
        </PropertiesFields>
      )}
    </Content>
  )
}

export { AssetForm, AssetFormSkeleton }
