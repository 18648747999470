import styled from "styled-components"

export const Container = styled.div`
  overflow-y: scroll;

  display: flex;
  flex-direction: column;
  align-items: start;
  height: 100%;

  border-top: 1px solid ${(props) => props.theme.palette.gray[300]};

  width: 100%;
`

export const InputContainer = styled.div`
  padding: 0px 12px 12px 12px;
  display: flex;
  width: 100%;
  gap: 5px;
`

export const TreeContainer = styled.div`
  width: 100%;
`
