import { ModalHedro } from "../../../../components/ModalHedro"
import { Content } from "./styles"
import { useAppTranslate } from "../../../../translate/useAppTranslate"
import { ChartType } from "../../../../components/Chart/entities/ChartData"
import { ConfigureAssets } from "../../../../components/ConfigureAssets"
import { useMemo, useState } from "react"
import { AssetChartDetails } from "../../../../store/api/analytics/entities"
import { ServiceConfigured } from "../../../../utils/entities/assets/ServiceConfigured"

interface ModalConfigureAssetsProps {
  onClose: () => void
  onBack: () => void
  onAction: (assets: AssetChartDetails[]) => void
  chartType: ChartType
  isLoading: boolean
}

export const ModalConfigureAssets = ({
  onClose,
  onBack,
  onAction,
  chartType,
  isLoading,
}: ModalConfigureAssetsProps) => {
  const {
    buttons,
    dashboard: { configureAssets },
  } = useAppTranslate()

  const [selectedAssets, setSelectedAssets] = useState<AssetChartDetails[]>([])

  const handleChangeSelectedAssets = (assets: AssetChartDetails[]) => {
    setSelectedAssets(assets)
  }

  const handleIsServiceDisabled = (service: ServiceConfigured) => {
    return !service.isConfigured
  }

  const isDisable = useMemo(() => {
    if (!selectedAssets.length) return true

    const hasAnyService = selectedAssets.find(
      (asset) => Object.keys(asset.services).length > 0
    )

    return !hasAnyService
  }, [selectedAssets])

  return (
    <ModalHedro
      onClose={onClose}
      onLeftButton={onBack}
      onAction={() => onAction(selectedAssets)}
      leftButtonLabel={buttons.back}
      title={configureAssets.title}
      subtitle={configureAssets.subtitle}
      size='xl'
      actionLabel={configureAssets.submit}
      isActionsDisabled={isDisable || isLoading}
    >
      <Content>
        <ConfigureAssets
          selectedAssets={selectedAssets}
          handleChangeSelectedAssets={handleChangeSelectedAssets}
          handleIsServiceDisabled={handleIsServiceDisabled}
          hideHealthService
          hasAxes
          chartType={chartType}
          canEditPath
        />
      </Content>
    </ModalHedro>
  )
}
