export const formatForDatetimeLocal = (
  dateString: string | number | readonly string[] | undefined
): string => {
  if (Array.isArray(dateString)) {
    return ""
  }
  if (!dateString) {
    return ""
  }
  const validDateString =
    typeof dateString === "string" || typeof dateString === "number"
      ? dateString
      : ""
  const date = new Date(validDateString)
  if (isNaN(date.getTime())) {
    return ""
  }
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, "0")
  const day = String(date.getDate()).padStart(2, "0")
  const hours = String(date.getHours()).padStart(2, "0")
  const minutes = String(date.getMinutes()).padStart(2, "0")

  return `${year}-${month}-${day}T${hours}:${minutes}`
}
