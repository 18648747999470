export const linechart = () => {
  return {
    chart: {
      animation: false,
      height: "700px",
      type: "line",
      zoomType: "xy",
      zooming: {
        mouseWheel: {
          enabled: false,
        },
      },
      panning: {
        enabled: true,
        type: "xy",
      },
      panKey: "shift", // key to alternate to pan
    },
    time: {
      useUTC: false,
    },
    stockTools: {
      gui: {
        buttons: [
          "simpleShapes",
          "lines",
          "crookedLines",
          "measure",
          "advanced",
          "toggleAnnotations",
          "separator",
          "verticalLabels",
          "flags",
          "separator",
          "zoomChange",
          "fullScreen",
          "separator",
          "currentPriceIndicator",
          "saveChart",
        ],
      },
    },
    xAxis: {
      ordinal: false,
    },
    rangeSelector: {
      enabled: false,
    },
    title: {
      text: " ",
    },
    accessibility: {
      enabled: false,
    },

    plotOptions: {
      series: {
        connectNulls: true,
        gapSize: 2,
        marker: {
          enabled: false,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "13px",
      },
    },

    legend: {
      enabled: true,
      align: "left",
      verticalAlign: "top",
      itemStyle: {
        lineHeight: "16px",
        fontSize: "13px",
        fontWeight: "bold",
      },
    },
  }
}
