import { Title } from "../../../../../components/Title"
import {
  Actions,
  Container,
  Details,
  Header,
  IconButton,
  Services,
} from "./styles"
import { Trash } from "phosphor-react"
import { useDeleteLinkSensorMutation } from "../../../../../store/store"
import { ModalHedro } from "../../../../../components/ModalHedro"
import { useState } from "react"
import { Text } from "../../../../../components/ui/Text"
import { useAppTranslate } from "../../../../../translate/useAppTranslate"
import { ModalConfigureSensor } from "../ModalConfigureSensor"
import { toast } from "react-toastify"
import { IconService } from "../../../../../components/IconService"
import { formatedDate } from "../../../../../utils/date"
import { cleanAndPadSerialNumber } from "../../../../../utils/cleanAndPadSerialNumber"
import { FlexContainer } from "../../../../../components/ui/FlexContainer"
import { LinkedSensor } from "../../entities/AssetDetails"

interface AssetWithSensorProps {
  sensor: LinkedSensor
  asset: {
    id: string
    name: string
  }
}

export const AssetWithSensor = ({ sensor, asset }: AssetWithSensorProps) => {
  const { assets } = useAppTranslate()

  const [removeSensor, mutation] = useDeleteLinkSensorMutation()

  const [open, setOpen] = useState(false)

  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleRemoveSensor = () => {
    removeSensor({
      assetId: asset.id,
      sensorId: sensor.id,
    })
      .unwrap()
      .then(() => {
        setOpen(false)
        toast.success(assets.sensors.deleteSuccess)
      })
      .catch(() => {
        toast.error(assets.sensors.deleteError)
      })
  }

  return (
    <>
      {open && (
        <ModalHedro
          onClose={() => setOpen(false)}
          onAction={handleRemoveSensor}
          actionLabel={assets.sensors.deleteSensor}
          title={assets.sensors.deleteSensor}
          isActionsDisabled={mutation.isLoading}
        >
          <Text fontSize='sm' fontWeight='regular' color='gray.700'>
            {`${assets.sensors.deleteModalMessage.part1} ${sensor.name} ${assets.sensors.deleteModalMessage.part2} "${asset.name}".`}
          </Text>
        </ModalHedro>
      )}
      {isModalOpen && (
        <ModalConfigureSensor
          handleCloseModal={handleCloseModal}
          assetId={asset.id}
        />
      )}
      <Header>
        <Title label='Sensor' size='sm' weight='medium' />
        <Actions>
          <IconButton onClick={() => setOpen(true)}>
            <Trash size={22} weight='bold' />
          </IconButton>
        </Actions>
      </Header>
      <Details>
        <Container>
          <FlexContainer align='start'>
            <Text>
              {sensor.name
                ? sensor.name
                : `S${cleanAndPadSerialNumber(sensor.serialNumber)}`}
            </Text>

            <Text>{sensor.serialNumber}</Text>
          </FlexContainer>

          <Services>
            {sensor.services.map(({ type, isConfigured }) => (
              <IconService key={type} type={type} isConfigured={isConfigured} />
            ))}
          </Services>
        </Container>

        {sensor.linkedAt && (
          <Text>
            {assets.form.sensors.linkedAt}: {formatedDate(sensor.linkedAt)}
          </Text>
        )}
      </Details>
    </>
  )
}
