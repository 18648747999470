import {
  ChartData,
  Service,
} from "../../../components/Chart/entities/ChartData"
import { AnalyticsSensors } from "../../../pages/Dashboard/entities/Analytics"
import {
  Analytic,
  AnalyticConfig,
  AssetAnalyticConfig,
  AssetChartDetails,
} from "./entities"
import { getChartServices } from "./utils/getChartServices"

export const transformAnalytics = (response: Analytic[]): ChartData[] => {
  return response.map((analytic) => transformAnalytic(analytic))
}

export const transformAnalytic = (response: Analytic): ChartData => {
  const configs: AnalyticConfig | AssetAnalyticConfig[] = JSON.parse(
    response.configs
  )

  const services =
    response.orientedBy === 1
      ? getServices(configs as AnalyticConfig, response.type)
      : []

  const assets =
    response.orientedBy === 2 ? getAssets(configs as AssetAnalyticConfig[]) : []

  const chart: ChartData = {
    dashboardId: response.dashboardId,
    id: response.id,
    orientedBy: response.orientedBy,
    period: response.period,
    services,
    assets,
    title: response.tag,
    type: response.type,
  }

  return chart
}

const getServices = (configs: AnalyticConfig, type: number): Service[] => {
  const analyticsObj: AnalyticsSensors = {}

  const keys = Object.keys(configs)

  keys.forEach((key) => {
    analyticsObj[key] = configs[key].map((config) => {
      return {
        mac: config.mac,
        name: config.name,
      }
    })
  })

  return getChartServices(analyticsObj, type)
}

const getAssets = (configs: AssetAnalyticConfig[]): AssetChartDetails[] => {
  return configs.map((config) => {
    return {
      id: config.assetId,
      path: config.caption,
      services: config.services,
    } as AssetChartDetails
  })
}
