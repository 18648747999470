import { createApi } from "@reduxjs/toolkit/dist/query/react"
import { thorBaseQuery } from "../utils"
import { ChartData } from "../../../components/Chart/entities/ChartData"
import {
  FetchAnalyticsRequest,
  CreateAssetAnalyticRequest,
  CreateSensorAnalyticRequest,
  DeleteAnalyticRequest,
} from "./entities"
import { transformAnalytic, transformAnalytics } from "./transforms"
import {
  onCreateAnalyticQueryStarted,
  onDeleteAnalyticQueryStarted,
} from "./update"

export const analyticsApi = createApi({
  reducerPath: "analyticsApi",
  baseQuery: thorBaseQuery(),
  endpoints: (build) => ({
    fetchAnalytics: build.query<ChartData[], FetchAnalyticsRequest>({
      query: ({ dashboardId }) => ({
        url: `/v1/proteu/analytics/${dashboardId}`,
        params: {
          offset: 0,
          limit: 100,
        },
      }),
      transformResponse: transformAnalytics,
    }),
    createSensorAnalytic: build.mutation<
      ChartData,
      CreateSensorAnalyticRequest
    >({
      query: ({ body }) => ({
        url: "/v1/proteu/analytics/sensor",
        method: "POST",
        body,
      }),
      transformResponse: transformAnalytic,
      onQueryStarted: onCreateAnalyticQueryStarted,
    }),
    createAssetAnalytic: build.mutation<ChartData, CreateAssetAnalyticRequest>({
      query: ({ body }) => ({
        url: "/v1/proteu/analytics/asset",
        method: "POST",
        body,
      }),
      transformResponse: transformAnalytic,
      onQueryStarted: onCreateAnalyticQueryStarted,
    }),
    deleteAnalytic: build.mutation<void, DeleteAnalyticRequest>({
      query: ({ analyticId }) => ({
        url: `/v1/proteu/analytics/${analyticId}`,
        method: "DELETE",
      }),
      onQueryStarted: onDeleteAnalyticQueryStarted,
    }),
  }),
})

export const {
  useFetchAnalyticsQuery,
  useCreateSensorAnalyticMutation,
  useDeleteAnalyticMutation,
  useCreateAssetAnalyticMutation,
} = analyticsApi
