import { FetchBaseQueryMeta } from "@reduxjs/toolkit/dist/query/react"
import { ApiAssetTree } from "./entities"
import {
  AssetData,
  AssetTreeData,
} from "../../../utils/entities/assets/AssetTreeData"
import {
  AssetHistory,
  AssetHistoryResponse,
} from "../../../utils/entities/assets"

export const transformAssetsStructure = (response: ApiAssetTree[]) => {
  const tree: AssetTreeData = {}

  const rootChildren: string[] = []

  response.forEach((asset) => {
    if (asset.isRoot) rootChildren.push(asset.id)

    tree[asset.id] = {
      index: asset.id,
      data: {
        path: asset.path,
        id: asset.id,
        name: asset.name,
        typeId: asset.typeId,
        services: asset.sensor ? asset.sensor.services : undefined,
      },
      canMove: false,
      canRename: false,
      children: asset.children,
      isFolder: asset.children.length > 0,
    }
  })

  tree["master"] = {
    index: "master",
    children: rootChildren,
    data: {
      id: "",
      name: "Master",
      typeId: 1,
    },
  }

  return tree
}

export const transformAssetsChildren = (
  response: ApiAssetTree[],
  meta: FetchBaseQueryMeta | undefined,
  assetId: string
): AssetData[] | undefined => {
  const find = response.find((asset) => asset.id === assetId)

  if (!find) return undefined

  return response.filter((asset) => find.children.includes(asset.id))
}

export const transformAssetHistoryResponse = (
  response: [AssetHistory[], number]
): AssetHistoryResponse => {
  if (!Array.isArray(response) || response.length !== 2) {
    throw new Error("Invalid response format or size")
  }

  const [data, total] = response

  if (!Array.isArray(data)) {
    throw new Error("Expected first element to be an array of AssetHistory")
  }

  if (typeof total !== "number") {
    throw new Error("Expected second element to be the total count (number)")
  }

  return {
    data: data.map((item) => ({
      id: item.id,
      assetId: item.assetId,
      eventGroup: item.eventGroup,
      eventType: item.eventType,
      eventInfo: item.eventInfo,
      createdAt: item.createdAt,
      deletedAt: item.deletedAt,
      executedAt: item.executedAt,
      alteredBy: item.alteredBy,
      latestAssetName: item.latestAssetName,
    })),
    total,
  }
}
