import * as S from "./styles"
import { LayoutOption } from "../LayoutOption"
import { Text } from "../../../../../../components/ui/Text"
import { useAppTranslate } from "../../../../../../translate/useAppTranslate"
import { FlexContainer } from "../../../../../../components/ui/FlexContainer"

interface SelectLayoutOptionProps {
  onChange: (index: number) => void
  selectedIndex: number
}

export const SelectLayoutOption = ({
  onChange,
  selectedIndex,
}: SelectLayoutOptionProps) => {
  const { modalEditDash } = useAppTranslate()

  const handleChangeSelected = (index: number) => {
    onChange(index)
  }

  return (
    <FlexContainer direction='column' align='start' gap={4} fullWidth>
      <Text fontSize='xs' fontWeight='semibold'>
        {modalEditDash.type_layout}
      </Text>
      <S.OptionsGroup>
        <S.Option
          onClick={() => handleChangeSelected(1)}
          selected={selectedIndex === 1}
        >
          <S.InputOption
            type='radio'
            checked={selectedIndex === 1}
            onChange={() => handleChangeSelected(1)}
          />
          <S.OptionAndSubtitle>
            <LayoutOption analyticsPerLine={1} />
            <S.Text>{modalEditDash.one_graphs_per_line}</S.Text>
          </S.OptionAndSubtitle>
        </S.Option>
        <S.Option
          onClick={() => handleChangeSelected(2)}
          selected={selectedIndex === 2}
        >
          <S.InputOption
            type='radio'
            checked={selectedIndex === 2}
            onChange={() => handleChangeSelected(2)}
          />
          <S.OptionAndSubtitle>
            <LayoutOption analyticsPerLine={2} />
            <S.Text>{modalEditDash.two_graphs_per_line}</S.Text>
          </S.OptionAndSubtitle>
        </S.Option>
        <S.Option
          onClick={() => handleChangeSelected(3)}
          selected={selectedIndex === 3}
        >
          <S.InputOption
            type='radio'
            checked={selectedIndex === 3}
            onChange={() => handleChangeSelected(3)}
          />
          <S.OptionAndSubtitle>
            <LayoutOption analyticsPerLine={3} />
            <S.Text>{modalEditDash.three_graphs_per_line}</S.Text>
          </S.OptionAndSubtitle>
        </S.Option>
      </S.OptionsGroup>
    </FlexContainer>
  )
}
