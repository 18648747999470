import { forwardRef, useRef } from "react"
import { QRCodeSVG } from "qrcode.react"
import HedroLogo from "../../../../../../../assets/logo.png"
import { Container } from "./styles"
import { Text } from "../../../../../../../components/ui/Text"

interface AssetQRCodeProps {
  assetUrl: string
  title: string
  height: number
  width: number
}

const AssetQRCode = forwardRef<HTMLDivElement, AssetQRCodeProps>(
  ({ assetUrl, title, height, width }, ref) => {
    const qrRef = useRef<HTMLDivElement>(null)
    const svgRef = useRef<SVGSVGElement>(null)

    return (
      <Container ref={ref || qrRef}>
        <QRCodeSVG
          value={assetUrl}
          ref={svgRef}
          level='H'
          width={width}
          height={height}
          imageSettings={{
            src: HedroLogo,
            x: undefined,
            y: undefined,
            height: 40,
            width: 40,
            opacity: 1,
            excavate: true,
          }}
        />

        <Text fontSize='lg' fontWeight='bold'>
          {title}
        </Text>
      </Container>
    )
  }
)

AssetQRCode.displayName = "AssetQRCode"

export default AssetQRCode
