import { Plus } from "phosphor-react"
import { Title } from "../../../../../../../../components/Title"
import { useAppTranslate } from "../../../../../../../../translate/useAppTranslate"
import { ActionContainer, ComponentPlus, Container } from "./styles"
// import { HistoryFilter } from "../HistoryFilter"
import { HistoryOrdening } from "../HistoryOrdering"
import { OrderingEvent } from "../../../../../../../../store/api/assets/entities"
import { HistoryFilter } from "../HistoryFilter"

interface HistoryHeaderProps {
  handleOpenCreateEventModal: () => void
  handleChangeOrdering: (ordering: OrderingEvent) => void
  handleChangeFilter: (filter: string) => void
  handleChangeRecursive: (recursive: boolean) => void
  filter: string
  isRecursive: boolean
}

export const HistoryHeader = ({
  handleOpenCreateEventModal,
  handleChangeOrdering,
  handleChangeFilter,
  handleChangeRecursive,
  filter,
  isRecursive,
}: HistoryHeaderProps) => {
  const { assets } = useAppTranslate()

  return (
    <Container>
      <Title label={assets.history.title} size='md' weight='medium' />
      <ActionContainer>
        <HistoryFilter
          handleChangeRecursive={handleChangeRecursive}
          handleChangeFilter={handleChangeFilter}
          filter={filter}
          isRecursive={isRecursive}
        />
        <HistoryOrdening handleChangeOrdering={handleChangeOrdering} />
        <ComponentPlus onClick={handleOpenCreateEventModal}>
          <Plus size={24} />
        </ComponentPlus>
      </ActionContainer>
    </Container>
  )
}
