import * as S from "./styles"
import { forwardRef } from "react"
import { ToggleFFT } from "../../../../components/ToggleFFT"
import { Text } from "../../../../components/ui/Text"
import { useChartContainerController } from "./controller/useChartContainerController"
import { Chart } from "../../../../components/Chart"
import { useAppTranslate } from "../../../../translate/useAppTranslate"
import {
  ChartData,
  ChartType,
} from "../../../../components/Chart/entities/ChartData"
import { ChartRef } from "../../../../components/NewChart"

interface ChartContainerProps {
  chart: ChartData
  companyId: number
  graphics_line: number
}

export const ChartContainer = forwardRef<ChartRef, ChartContainerProps>(
  ({ chart, graphics_line, companyId }, ref) => {
    const { fftType, handleToggleFFT } = useChartContainerController({
      type: chart.type,
      id: chart.id,
    })

    const translate = useAppTranslate()

    return (
      <S.Container $graphics_line={graphics_line}>
        <S.MainChart>
          <S.BodyChart>
            <Chart
              ref={ref}
              chart={chart}
              realtime={true}
              fftType={fftType}
              companyId={companyId}
            />
          </S.BodyChart>
        </S.MainChart>

        {chart.type === ChartType.TWO && (
          <S.Footer>
            <S.TextWrapper $align='end'>
              <Text
                fontWeight={fftType === "Acceleration" ? "bold" : "regular"}
              >
                {translate.chart.fftAcceleration}
              </Text>
            </S.TextWrapper>
            <ToggleFFT
              onChange={handleToggleFFT}
              checked={fftType === "Velocity"}
            />
            <S.TextWrapper $align='start'>
              <Text fontWeight={fftType === "Velocity" ? "bold" : "regular"}>
                {translate.chart.fftVelocity}
              </Text>
            </S.TextWrapper>
          </S.Footer>
        )}
      </S.Container>
    )
  }
)

ChartContainer.displayName = "ChartContainer"
