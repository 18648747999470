import {
  SquaresFour,
  Crosshair,
  FileText,
  Broadcast,
  Article,
  Siren,
} from "@phosphor-icons/react"
import { PermissionsProteu } from "../store/features/user/user.interfaces"
import { useAppTranslate } from "../translate/useAppTranslate"
import { Folder } from "phosphor-react"

export const useMenuRoutes = () => {
  const { lateralMenu } = useAppTranslate()

  return [
    {
      page: lateralMenu.routes.assets.title,
      href: "/assets",
      icon: <Folder size={24} />,
      permissions: [PermissionsProteu.AssetsEnable],
    },
    {
      page: lateralMenu.routes.dashboard,
      href: "/dashboard",
      icon: <SquaresFour size={24} />,
      permissions: PermissionsProteu.DashboardEnable,
    },
    {
      page: lateralMenu.routes.reports.title,
      href: null,
      icon: <FileText size={24} />,
      permissions: [
        PermissionsProteu.ReportsEnable,
        PermissionsProteu.HistoricalAnalysisEnable,
      ],
      tree: [
        {
          name: lateralMenu.routes.reports.download,
          href: "/reports",
          permission: PermissionsProteu.ReportsEnable,
        },
        {
          name: lateralMenu.routes.reports.historicalAnalysis,
          href: "/historicalanalysis",
          permission: PermissionsProteu.HistoricalAnalysisEnable,
        },
      ],
    },
    {
      page: lateralMenu.routes.alarms.title,
      href: null,
      icon: <Siren size={24} />,
      permissions: [
        PermissionsProteu.AlarmsConfigEnable,
        PermissionsProteu.AlarmsLogsEnable,
      ],
      tree: [
        {
          name: lateralMenu.routes.alarms.logs,
          href: "/alarmslogs",
          permission: PermissionsProteu.AlarmsLogsEnable,
        },
        {
          name: lateralMenu.routes.alarms.config,
          href: "/alarmsconfig",
          permission: PermissionsProteu.AlarmsConfigEnable,
        },
      ],
    },
    {
      page: lateralMenu.routes.sensors.title,
      href: null,
      icon: <Broadcast size={24} />,
      permissions: [
        PermissionsProteu.SensorsConfigEnable,
        PermissionsProteu.SensorsProvEnable,
      ],
      tree: [
        {
          name: lateralMenu.routes.sensors.prov,
          href: "/sensorsprov",
          permission: PermissionsProteu.SensorsProvEnable,
        },
        {
          name: lateralMenu.routes.sensors.config,
          href: "/sensorsconfig",
          permission: PermissionsProteu.SensorsConfigEnable,
        },
      ],
    },
    {
      page: lateralMenu.routes.gateways,
      href: "/gateways",
      icon: <Crosshair size={24} />,
      permissions: PermissionsProteu.GatewaysEnable,
    },
    {
      page: lateralMenu.routes.manager.title,
      href: null,
      icon: <Article size={24} />,
      permissions: [
        PermissionsProteu.ManagerCompaniesEnable,
        PermissionsProteu.ManagerSalesEnable,
        PermissionsProteu.ManagerUsersEnable,
      ],
      tree: [
        {
          name: lateralMenu.routes.manager.company,
          href: "/registercompany",
          permission: PermissionsProteu.ManagerCompaniesEnable,
        },
        {
          name: lateralMenu.routes.manager.users,
          href: "/registeruser",
          permission: PermissionsProteu.ManagerUsersEnable,
        },
        {
          name: lateralMenu.routes.manager.sales,
          href: "/registersales",
          permission: PermissionsProteu.ManagerSalesEnable,
        },
      ],
    },
  ]
}
