import { useMemo } from "react"
import {
  useCreateHistoryAssetMutation,
  useEditHistoryAssetMutation,
} from "../../../../../store/api/assets/slice"
import { toast } from "react-toastify"
import { useAppTranslate } from "../../../../../translate/useAppTranslate"

interface CreateEventHistoryProps {
  assetId: string
  eventInfo: {}
  eventType: string
  executedAt: string
}

export const useEventHistory = () => {
  const {
    assets: { history },
  } = useAppTranslate()

  const [createHistoryAsset, mutationCreate] = useCreateHistoryAssetMutation()
  const [editHistoryAsset, mutationEdit] = useEditHistoryAssetMutation()

  const isLoading = useMemo(
    () => mutationCreate.isLoading || mutationEdit.isLoading,
    [mutationCreate.isLoading, mutationEdit.isLoading]
  )
  const handleCreateEventHistory = async ({
    assetId,
    eventInfo,
    eventType,
    executedAt,
  }: CreateEventHistoryProps) => {
    if (!assetId) return

    try {
      await createHistoryAsset({
        assetId,
        eventGroup: "user",
        eventInfo: eventInfo,
        eventType: eventType,
        executedAt: executedAt,
      }).unwrap()

      toast.success(history.eventSuccessfullyAddedToHistory)

      return
    } catch (error) {
      toast.error(history.errorAddingEventToHistory)
    }
  }

  const handleEditEventHistory = async ({
    assetId,
    eventId,
    eventInfo,
    eventType,
    executedAt,
  }: CreateEventHistoryProps & { eventId: string }) => {
    if (!assetId) return

    try {
      await editHistoryAsset({
        assetId,
        eventId,
        eventGroup: "user",
        eventInfo: eventInfo,
        eventType: eventType,
        executedAt: executedAt,
      }).unwrap()

      toast.success(history.eventEditedSuccessfully)

      return
    } catch (error) {
      toast.error(history.errorEditingEventInHistory)
    }
  }

  return {
    handleCreateEventHistory,
    handleEditEventHistory,
    isLoading,
  }
}
