import { useState, useEffect } from "react"
import {
  CaretComponent,
  CheckboxContainer,
  Container,
  Tooltip,
  TooltipWrapper,
} from "./styles"
import { Checkbox } from "../../../../../../../../components/ui/Checkbox"
import { Text } from "../../../../../../../../components/ui/Text"
import { FlexContainer } from "../../../../../../../../components/ui/FlexContainer"
import { Funnel, CaretDown, CaretUp } from "phosphor-react"
import { useAppTranslate } from "../../../../../../../../translate/useAppTranslate"
import {
  EventPlatformType,
  EventUserType,
} from "../../../../../utils/eventType"

interface HistoryFilterProps {
  handleChangeFilter: (filter: string) => void
  handleChangeRecursive: (recursive: boolean) => void
  filter: string
  isRecursive: boolean
}

export const HistoryFilter = ({
  handleChangeFilter,
  handleChangeRecursive,
  isRecursive,
  filter,
}: HistoryFilterProps) => {
  const {
    assets: { history },
  } = useAppTranslate()

  const eventDescriptions = {
    [EventPlatformType.ASSET_CREATED]: history.assetCreated,
    [EventPlatformType.ASSET_UPDATED]: history.assetUpdated,
    [EventPlatformType.ASSET_DELETED]: history.assetDeleted,
    [EventPlatformType.ASSET_MOVED_TO_NEW_PARENT]:
      history.assetMovedToNewParent,
    [EventPlatformType.SENSOR_LINKED_TO_ASSET]: history.sensorLinkedToAsset,
    [EventPlatformType.SENSOR_UNLINKED_FROM_ASSET]:
      history.sensorUnlinkedFromAsset,
  }

  const userEventDescriptions = {
    [EventUserType.EQUIPMENT_FAILURE]: history.equipmentFailure,
    [EventUserType.MAINTENANCE_CORRECTIVE]:
      history.correctiveMaintenanceShutdown,
    [EventUserType.MAINTENANCE_PREVENTIVE]:
      history.preventiveMaintenanceShutdown,
    [EventUserType.OPERATIONAL_STOP]: history.operationalShutdown,
    [EventUserType.PLANNED_STOP]: history.scheduledShutdown,
    [EventUserType.INSPECTION]: history.inspection,
    [EventUserType.OTHER]: history.other,
  }

  type EventDescriptionKeys = keyof typeof eventDescriptions
  type UserEventDescriptionKeys = keyof typeof userEventDescriptions

  const [showAxes, setShowAxes] = useState(false)
  const [selectedOptionsPlataform, setSelectedOptionsPlataform] = useState<
    Record<EventDescriptionKeys, boolean>
  >(
    Object.keys(eventDescriptions).reduce(
      (acc, event) => {
        acc[event as EventDescriptionKeys] = false
        return acc
      },
      {} as Record<EventDescriptionKeys, boolean>
    )
  )

  const [selectedOptionsUsers, setSelectedOptionsUsers] = useState<
    Record<UserEventDescriptionKeys, boolean>
  >(
    Object.keys(userEventDescriptions).reduce(
      (acc, event) => {
        acc[event as UserEventDescriptionKeys] = false
        return acc
      },
      {} as Record<UserEventDescriptionKeys, boolean>
    )
  )

  const [selectAllPlataform, setSelectAllPlataform] = useState(false)
  const [selectAllUsers, setSelectAllUsers] = useState(false)
  const [isDropdownOpenPlataform, setIsDropdownOpenPlataform] = useState(false)
  const [isDropdownOpenUsers, setIsDropdownOpenUsers] = useState(false)

  useEffect(() => {
    const selectedEvents = filter.split(",").map((event) => event.trim())
    const updatedOptionsPlataform: Record<EventDescriptionKeys, boolean> =
      Object.keys(eventDescriptions).reduce(
        (acc, event) => {
          acc[event as EventDescriptionKeys] = selectedEvents.includes(event)
          return acc
        },
        {} as Record<EventDescriptionKeys, boolean>
      )

    const updatedOptionsUsers: Record<UserEventDescriptionKeys, boolean> =
      Object.keys(userEventDescriptions).reduce(
        (acc, event) => {
          acc[event as UserEventDescriptionKeys] =
            selectedEvents.includes(event)
          return acc
        },
        {} as Record<UserEventDescriptionKeys, boolean>
      )

    setSelectedOptionsPlataform(updatedOptionsPlataform)
    setSelectedOptionsUsers(updatedOptionsUsers)
    setSelectAllPlataform(
      Object.values(updatedOptionsPlataform).every((value) => value)
    )
    setSelectAllUsers(
      Object.values(updatedOptionsUsers).every((value) => value)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter])

  const handleParentCheckboxChangePlataform = () => {
    const newSelectAll = !selectAllPlataform
    setSelectAllPlataform(newSelectAll)
    const updatedOptions = Object.keys(selectedOptionsPlataform).reduce(
      (acc, option) => {
        acc[option as EventDescriptionKeys] = newSelectAll
        return acc
      },
      {} as Record<EventDescriptionKeys, boolean>
    )
    setSelectedOptionsPlataform(updatedOptions)
    const newFilter = newSelectAll
      ? Object.keys(eventDescriptions).join(",")
      : ""
    handleChangeFilter(newFilter)
  }

  const handleParentCheckboxChangeUsers = () => {
    const newSelectAll = !selectAllUsers
    setSelectAllUsers(newSelectAll)
    const updatedOptions = Object.keys(selectedOptionsUsers).reduce(
      (acc, option) => {
        acc[option as UserEventDescriptionKeys] = newSelectAll
        return acc
      },
      {} as Record<UserEventDescriptionKeys, boolean>
    )
    setSelectedOptionsUsers(updatedOptions)
    const newFilter = newSelectAll
      ? Object.keys(userEventDescriptions).join(",")
      : ""
    handleChangeFilter(newFilter)
  }

  const handleChildCheckboxChangePlataform = (option: EventDescriptionKeys) => {
    const updatedOptions = {
      ...selectedOptionsPlataform,
      [option]: !selectedOptionsPlataform[option],
    }
    setSelectedOptionsPlataform(updatedOptions)
    setSelectAllPlataform(Object.values(updatedOptions).every((value) => value))

    const newFilter = Object.keys(updatedOptions)
      .filter((event) => updatedOptions[event as EventDescriptionKeys])
      .join(",")
    handleChangeFilter(newFilter)
  }

  const handleChildCheckboxChangeUsers = (option: UserEventDescriptionKeys) => {
    const updatedOptions = {
      ...selectedOptionsUsers,
      [option]: !selectedOptionsUsers[option],
    }
    setSelectedOptionsUsers(updatedOptions)
    setSelectAllUsers(Object.values(updatedOptions).every((value) => value))

    const newFilter = Object.keys(updatedOptions)
      .filter((event) => updatedOptions[event as UserEventDescriptionKeys])
      .join(",")
    handleChangeFilter(newFilter)
  }

  const toggleDropdownPlataform = () => {
    setIsDropdownOpenPlataform(!isDropdownOpenPlataform)
  }

  const toggleDropdownUsers = () => {
    setIsDropdownOpenUsers(!isDropdownOpenUsers)
  }

  const handleRecursive = (recursive: boolean) => {
    handleChangeRecursive(recursive)
  }

  return (
    <Container>
      <Funnel size={24} onClick={() => setShowAxes(!showAxes)} />
      {showAxes && (
        <TooltipWrapper>
          <Tooltip>
            <FlexContainer gap={8} align='start' padding='10px'>
              <FlexContainer direction='row' gap={12} align='start'>
                <Checkbox
                  checked={isRecursive}
                  onChange={() => handleRecursive(!isRecursive)}
                />
                <Text>{history.viewChildrensEvents}</Text>
              </FlexContainer>

              <FlexContainer direction='row' gap={12} align='start'>
                <Checkbox
                  checked={selectAllPlataform}
                  indeterminate={
                    !selectAllPlataform &&
                    Object.values(selectedOptionsPlataform).some(
                      (value) => value
                    )
                  }
                  onChange={handleParentCheckboxChangePlataform}
                />
                <Text>{history.platform}</Text>

                <CaretComponent onClick={toggleDropdownPlataform}>
                  {isDropdownOpenPlataform ? (
                    <CaretUp size={20} />
                  ) : (
                    <CaretDown size={20} />
                  )}
                </CaretComponent>
              </FlexContainer>

              {isDropdownOpenPlataform && (
                <CheckboxContainer>
                  {Object.keys(eventDescriptions).map((event) => (
                    <FlexContainer
                      key={event}
                      direction='row'
                      gap={8}
                      justify='start'
                    >
                      <Checkbox
                        checked={
                          selectedOptionsPlataform[
                            event as EventDescriptionKeys
                          ]
                        }
                        onChange={() =>
                          handleChildCheckboxChangePlataform(
                            event as EventDescriptionKeys
                          )
                        }
                      />
                      <Text>
                        {eventDescriptions[event as EventDescriptionKeys]}
                      </Text>
                    </FlexContainer>
                  ))}
                </CheckboxContainer>
              )}

              <FlexContainer direction='row' gap={12} align='start'>
                <Checkbox
                  checked={selectAllUsers}
                  onChange={handleParentCheckboxChangeUsers}
                  indeterminate={
                    !selectAllUsers &&
                    Object.values(selectedOptionsUsers).some((value) => value)
                  }
                />
                <Text>{history.users}</Text>

                <CaretComponent onClick={toggleDropdownUsers}>
                  {isDropdownOpenUsers ? (
                    <CaretUp size={20} />
                  ) : (
                    <CaretDown size={20} />
                  )}
                </CaretComponent>
              </FlexContainer>

              {isDropdownOpenUsers && (
                <CheckboxContainer>
                  {Object.keys(userEventDescriptions).map((event) => (
                    <FlexContainer
                      key={event}
                      direction='row'
                      gap={8}
                      justify='start'
                    >
                      <Checkbox
                        checked={
                          selectedOptionsUsers[
                            event as UserEventDescriptionKeys
                          ]
                        }
                        onChange={() =>
                          handleChildCheckboxChangeUsers(
                            event as UserEventDescriptionKeys
                          )
                        }
                      />
                      <Text>
                        {
                          userEventDescriptions[
                            event as UserEventDescriptionKeys
                          ]
                        }
                      </Text>
                    </FlexContainer>
                  ))}
                </CheckboxContainer>
              )}
            </FlexContainer>
          </Tooltip>
        </TooltipWrapper>
      )}
    </Container>
  )
}
