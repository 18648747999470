import { useCallback, useContext, useEffect, useMemo, useRef } from "react"
import { PermissionsProteu } from "../../../../store/features/user/user.interfaces"
import { useFetchAnalyticsQuery } from "../../../../store/store"
import { ValidatePermission } from "../../../../utils/validatePermission"
import {
  CreateChartContext,
  CreateChartContextDTO,
} from "../../context/CreateChartContext"
import { FloatingButton } from "../../../../components/Buttons/FloatingButton"
import { ModalWrapper } from "../ModalWrapper"
import { ContainerCharts } from "./styles"
import { NewChartContainer } from "../NewChartContainer"
import { ChartContainer } from "../ChartContainer"
import { useDeleteChart } from "../../hooks/useDeleteChart"
import { FlexContainer } from "../../../../components/ui/FlexContainer"
import { useAppTranslate } from "../../../../translate/useAppTranslate"
import { ContainerWrapper } from "../../styles"
import {
  ChartData,
  ChartType,
  OrientedBy,
} from "../../../../components/Chart/entities/ChartData"
import { NewChart } from "../../../../components/NewChart"
import { HDR_SERVICES_TYPE } from "hdr-process-data"
import { useAssetSeries } from "../../hooks/useAssetsSeries"
import { Dashboard } from "../../entities/Dashboard"

interface DashboardWrapperProps {
  dashboard: Dashboard
}

export const DashboardWrapper = ({ dashboard }: DashboardWrapperProps) => {
  const translate = useAppTranslate()

  const { handleOpenConfigureChart } = useContext(
    CreateChartContext
  ) as CreateChartContextDTO

  const {
    data: charts,
    isLoading: isLoadingAnalytics,
    isFetching: isFetchingAnalytics,
  } = useFetchAnalyticsQuery({
    dashboardId: dashboard.id,
  })

  const hasCharts = useMemo(() => charts && charts.length > 0, [charts])

  const isLoading = useMemo(
    () => isLoadingAnalytics || isFetchingAnalytics,
    [isLoadingAnalytics, isFetchingAnalytics]
  )

  return (
    <>
      <ValidatePermission permission={PermissionsProteu.DashboardWrite}>
        <FloatingButton onClick={handleOpenConfigureChart} />
      </ValidatePermission>

      <ModalWrapper dashboardId={dashboard.id} />

      {hasCharts && charts && !isLoading && (
        <ContainerCharts $graphics_line={dashboard.layout}>
          {charts.map((chart) => (
            <RenderChart
              key={chart.id}
              chart={chart}
              dashboardLayout={dashboard.layout}
              dashboardId={dashboard.id}
              companyId={Number(dashboard.orgId)}
            />
          ))}
        </ContainerCharts>
      )}

      {!hasCharts && !isLoading && (
        <ContainerWrapper>
          <FlexContainer>
            {translate.dashboard.no_graphics_configured}
          </FlexContainer>
          {translate.dashboard.information_to_add_graph}
        </ContainerWrapper>
      )}
    </>
  )
}

interface RenderChartProps {
  chart: ChartData
  dashboardLayout: number
  dashboardId: number
  companyId: number
}

export const RenderChart = ({
  chart,
  dashboardLayout,
  dashboardId,
  companyId,
}: RenderChartProps) => {
  const translate = useAppTranslate()

  const chartRef = useRef(chart)

  useEffect(() => {
    if (chartRef.current.id !== chart.id) {
      chartRef.current = chart
    }
  }, [chart])

  const memoizedChart = chartRef.current

  const { handleDeleteChart, isDeleteDisabled } = useDeleteChart()

  const handleDelete = useCallback(
    async (chartId: string) => {
      await handleDeleteChart(chartId, dashboardId)
    },
    [handleDeleteChart, dashboardId]
  )

  const getXAxisTitle = useCallback(
    (type: ChartType) => {
      if (type === ChartType.ADL) return ""

      return translate.chart.xAxisTitle[type]
    },
    [translate.chart.xAxisTitle]
  )

  const getYAxisTitle = useCallback(
    (chart: ChartData) => {
      const typesSet = new Set<HDR_SERVICES_TYPE>()

      const services: HDR_SERVICES_TYPE[] = []

      if (!chart.assets) return "Valores"

      chart.assets.forEach((asset) => {
        Object.keys(asset.services).forEach((service) => {
          services.push(Number(service) as HDR_SERVICES_TYPE)
        })
      })

      services.forEach((service) => typesSet.add(service))

      //if you have several units mixed
      const typesArray = Array.from(typesSet)
      if (typesArray.length !== 1) return "Valores"

      const singleType = typesArray[0]
      return translate.chart.yAxisTitle[singleType]
    },
    [translate.chart.yAxisTitle]
  )

  if (memoizedChart.orientedBy === OrientedBy.ASSET) {
    return (
      <NewChartContainer
        key={memoizedChart.id}
        chartsPerLine={dashboardLayout}
        chartId={memoizedChart.id}
        onDelete={handleDelete}
        title={memoizedChart.title}
        type={memoizedChart.type}
        isDeleteDisabled={false}
      >
        <NewChart
          chart={memoizedChart}
          xAxisType={memoizedChart.type === 1 ? "datetime" : "linear"}
          useChartSeries={useAssetSeries}
          xAxisTitle={getXAxisTitle(memoizedChart.type)}
          yAxisTitle={getYAxisTitle(memoizedChart)}
        />
      </NewChartContainer>
    )
  }

  return (
    <NewChartContainer
      key={memoizedChart.id}
      chartsPerLine={dashboardLayout}
      chartId={memoizedChart.id}
      title={memoizedChart.title}
      type={memoizedChart.type}
      onDelete={handleDelete}
      isDeleteDisabled={isDeleteDisabled}
      hideToogleFFT
    >
      <ChartContainer
        graphics_line={dashboardLayout}
        chart={memoizedChart}
        companyId={companyId}
      />
    </NewChartContainer>
  )
}
