import { createApi } from "@reduxjs/toolkit/dist/query/react"
import { thorBaseQuery } from "../utils"

import {
  transformAssetHistoryResponse,
  transformAssetsChildren,
  transformAssetsStructure,
} from "./transforms"
import {
  CreateAssetRequest,
  FetchUnlinkSensors,
  LinkSensorData,
  UpdateAssetRequest,
  FetchUnlinkSensorsResponse,
  UnlinkSensorRequest,
  FetchAssetsTreeData,
  DeleteAssetResponse,
  UploadAssetImageRequest,
  UploadAssetImageResponse,
  UploadAssetAttachmentRequest,
  AssetAttachmentsResponse,
  DeleteAssetAttachmentRequest,
  FetchGetAssetsHistory,
  CreateAssetsHistory,
  DeleteAssetHistoryRequest,
  EditAssetHistoryRequest,
} from "./entities"
import {
  onCreateQueryStarted,
  onDeleteAssetAttachmentQueryStarted,
  onDeleteQueryStarted,
  onUnlinkSensorQueryStarted,
  onUpdateQueryStarted,
  onUploadAssetImageQueryStarted,
  onUploadAssetAttachmentQueryStarted,
  onDeleteAssetImageQueryStarted,
  upCreateAssetHistoryCache,
  onDeleteAssetEventQueryStarted,
  onUpdateAssetEventQueryStarted,
} from "./updates"
import {
  AssetData,
  AssetTreeData,
} from "../../../utils/entities/assets/AssetTreeData"
import {
  AssetDetails,
  AssetType,
} from "../../../pages/v3/Assets/entities/AssetDetails"
import {
  AssetHistory,
  AssetHistoryResponse,
} from "../../../utils/entities/assets"

export const assetsApi = createApi({
  reducerPath: "assetsApi",
  baseQuery: thorBaseQuery(),
  tagTypes: ["LIST_SENSORS", "DETAILS", "FETCH_ASSETS", "HISTORY"],
  endpoints: (build) => ({
    fetchAssets: build.query<AssetTreeData, FetchAssetsTreeData>({
      query: ({ services }) => {
        return {
          url: "/v1/assets/structure",
          method: "GET",
          params: {
            services,
          },
        }
      },
      transformResponse: transformAssetsStructure,
      providesTags: ["FETCH_ASSETS"],
    }),

    fetchAssetChildren: build.query<AssetData[] | undefined, string>({
      query: (_) => {
        return {
          url: `/v1/assets/structure`,
          method: "GET",
        }
      },
      transformResponse: transformAssetsChildren,
    }),

    fetchAssetDetails: build.query<AssetDetails, string>({
      query: (assetId) => {
        return {
          url: `/v1/assets/${assetId}`,
          method: "GET",
        }
      },
      providesTags: (result) => {
        return result ? [{ type: "DETAILS", id: result.id }] : []
      },
    }),

    createAsset: build.mutation<AssetDetails, CreateAssetRequest>({
      query: ({ parentId, data }) => {
        return {
          url: "/v1/assets",
          method: "POST",
          body: {
            propertyValues: Object.fromEntries(
              data.properties.map(({ property, value }) => [property, value])
            ),
            assetSourceId: parentId,
            description: data.description,
            name: data.name,
            sensorProvId: data.sensorId,
            linkedAt: data.linkedAt
              ? new Date(data.linkedAt).toISOString()
              : undefined,
            typeId: Number(data.type),
          },
        }
      },
      onQueryStarted: onCreateQueryStarted,
      invalidatesTags: ["LIST_SENSORS", "HISTORY"],
    }),

    deleteAsset: build.mutation<DeleteAssetResponse, string>({
      query: (assetId) => {
        return {
          url: `/v1/assets/${assetId}`,
          method: "DELETE",
        }
      },
      onQueryStarted: onDeleteQueryStarted,
      invalidatesTags: ["LIST_SENSORS"],
    }),

    updateAsset: build.mutation<AssetDetails, UpdateAssetRequest>({
      query: ({ assetId, data }) => {
        return {
          url: `/v1/assets/${assetId}`,
          method: "PATCH",
          body: data,
        }
      },
      onQueryStarted: onUpdateQueryStarted,
      invalidatesTags: ["HISTORY"],
    }),

    fetchUnlinkedSensors: build.query<
      FetchUnlinkSensorsResponse,
      FetchUnlinkSensors
    >({
      query: (params) => {
        return {
          url: "/v1/assets/sensors/unlinked",
          params: {
            offset: params.offset,
            limit: params.limit,
            search: params.search,
            order_by: params.orderBy,
            sort_by: params.sortBy,
          },
          method: "GET",
        }
      },
      providesTags: ["LIST_SENSORS", "HISTORY"],
    }),

    linkSensor: build.mutation<void, LinkSensorData>({
      query: ({ assetId, linkedAt, sensorId }) => {
        return {
          url: `/v1/assets/${assetId}/sensors`,
          method: "POST",
          body: {
            linkedAt,
            sensorProvId: sensorId,
          },
        }
      },

      invalidatesTags: (result, error, { assetId }) => [
        "LIST_SENSORS",
        { type: "DETAILS", assetId },
        "HISTORY",
      ],
    }),

    deleteLinkSensor: build.mutation<void, UnlinkSensorRequest>({
      query: ({ assetId, sensorId }) => {
        return {
          url: `/v1/assets/${assetId}/sensors/${sensorId}`,
          method: "DELETE",
        }
      },
      onQueryStarted: onUnlinkSensorQueryStarted,
      invalidatesTags: ["LIST_SENSORS", "HISTORY"],
    }),

    fetchAssetTypes: build.query<AssetType[], void>({
      query: (_) => {
        return {
          url: "/v1/assets/types",
          method: "GET",
        }
      },
    }),

    fetchAssetAttachments: build.query<AssetAttachmentsResponse[], string>({
      query: (assetId) => {
        return {
          url: `/v1/assets/${assetId}/attachment`,
          method: "GET",
        }
      },
    }),

    uploadAssetAttach: build.mutation<
      AssetAttachmentsResponse,
      UploadAssetAttachmentRequest
    >({
      query: ({ assetId, file }) => {
        const extension = file.type.split("/").pop()

        if (!extension) {
          throw new Error("Invalid file type")
        }

        const data = new FormData()
        data.append("extension", extension)
        data.append("attachment", file, file.name)
        data.append("description", "")

        return {
          url: `/v1/assets/${assetId}/attachment`,
          method: "POST",
          body: data,
          formData: true,
        }
      },
      onQueryStarted: onUploadAssetAttachmentQueryStarted,
    }),

    deleteAssetAttachment: build.mutation<void, DeleteAssetAttachmentRequest>({
      query: ({ assetId, attachmentId }) => {
        return {
          url: `/v1/assets/${assetId}/attachment/${attachmentId}`,
          method: "DELETE",
        }
      },
      onQueryStarted: onDeleteAssetAttachmentQueryStarted,
    }),

    uploadAssetImage: build.mutation<
      UploadAssetImageResponse,
      UploadAssetImageRequest
    >({
      query: ({ assetId, file }) => {
        const data = new FormData()
        data.append("extension", file.type.replace("image/", ""))
        data.append("picture", file, file.name)

        return {
          url: `/v1/assets/${assetId}/picture`,
          method: "PUT",
          body: data,
          formData: true,
        }
      },
      onQueryStarted: onUploadAssetImageQueryStarted,
    }),

    fetchAssetHistory: build.query<AssetHistoryResponse, FetchGetAssetsHistory>(
      {
        query: (params) => {
          return {
            url: `/v1/assets/${params.assetId}/history`,
            method: "GET",
            params: {
              offset: params.offset,
              limit: params.limit,
              order_by: params.orderBy,
              sort_by: params.sortBy,
              search: params.search,
              recursive: params.recursive,
            },
          }
        },

        transformResponse: transformAssetHistoryResponse,
        providesTags: ["HISTORY"],
      }
    ),

    createHistoryAsset: build.mutation<AssetHistory, CreateAssetsHistory>({
      query: (params) => {
        return {
          url: `/v1/assets/${params.assetId}/history`,
          method: "POST",
          body: {
            eventGroup: params.eventGroup,
            eventInfo: params.eventInfo,
            eventType: params.eventType,
            executedAt: params.executedAt,
          },
        }
      },
      onQueryStarted: upCreateAssetHistoryCache,
      invalidatesTags: ["HISTORY"],
    }),

    editHistoryAsset: build.mutation<AssetHistory, EditAssetHistoryRequest>({
      query: (params) => {
        return {
          url: `/v1/assets/${params.assetId}/history/${params.eventId}`,
          method: "PATCH",
          body: {
            eventGroup: params.eventGroup,
            eventInfo: params.eventInfo,
            eventType: params.eventType,
            executedAt: params.executedAt,
          },
        }
      },
      onQueryStarted: onUpdateAssetEventQueryStarted,
      invalidatesTags: ["HISTORY"],
    }),

    deleteHistoryAsset: build.mutation<void, DeleteAssetHistoryRequest>({
      query: (params) => {
        return {
          url: `/v1/assets/${params.assetId}/history/${params.eventId}`,
          method: "DELETE",
        }
      },
      onQueryStarted: onDeleteAssetEventQueryStarted,
      invalidatesTags: ["HISTORY"],
    }),

    deleteAssetImage: build.mutation<void, string>({
      query: (assetId) => {
        return {
          url: `/v1/assets/${assetId}/picture`,
          method: "DELETE",
        }
      },
      onQueryStarted: onDeleteAssetImageQueryStarted,
    }),
  }),
})

export const {
  useFetchAssetDetailsQuery,
  useFetchAssetsQuery,
  useCreateAssetMutation,
  useDeleteAssetMutation,
  useUpdateAssetMutation,
  useFetchUnlinkedSensorsQuery,
  useLinkSensorMutation,
  useFetchAssetChildrenQuery,
  useDeleteLinkSensorMutation,
  useFetchAssetTypesQuery,
  useUploadAssetImageMutation,
  useDeleteAssetImageMutation,
  useFetchAssetAttachmentsQuery,
  useUploadAssetAttachMutation,
  useDeleteAssetAttachmentMutation,
  useFetchAssetHistoryQuery,
  useCreateHistoryAssetMutation,
  useDeleteHistoryAssetMutation,
  useEditHistoryAssetMutation,
} = assetsApi
