import React, { ComponentPropsWithoutRef, ElementType } from "react"
import * as S from "./styles"
import { CardHeader } from "./components/CardHeader"
import { CardIcon } from "./components/CardIcon"
import { Skeleton } from "@mui/material"
import { CardContent, CardDescription, CardFooter } from "./styles"
import { useAppTranslate } from "../../translate/useAppTranslate"

type CardProps<T extends ElementType> = {
  title: string
  icon?: JSX.Element
  createdAt?: Date
  executedAt?: Date
  children?: React.ReactNode
  tag?: string
  status?: "read" | "unread"
  type?: "notification" | "message"
  skeleton?: boolean
  eventGroup?: string
  action?: () => void
  assetId: number
} & ComponentPropsWithoutRef<T>

export const Card = <C extends ElementType>({
  as: Component = S.CardWrapper,
  title,
  children,
  createdAt,
  executedAt,
  eventGroup = "user",
  icon,
  tag,
  status = "read",
  type = "message",
  skeleton = false,
  action = () => {},
  assetId,
  ...props
}: CardProps<C>) => {
  const {
    assets: { history },
  } = useAppTranslate()

  const dateToString = (date: Date | undefined) => {
    if (!date) return ""
    return date.toLocaleString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    })
  }

  return (
    <Component {...props}>
      <CardIcon status={status} icon={icon} type={type} skeleton={skeleton} />
      <S.Wrapper>
        <CardHeader
          title={title}
          icon={icon}
          createdAt={createdAt}
          status={status}
          subTitle={tag}
          type={type}
          skeleton={skeleton}
          onClick={action}
        />
        {skeleton ? (
          <>
            <Skeleton variant='text' width='100%' />
            <Skeleton variant='text' width='100%' />
            <Skeleton variant='text' width='100%' />
          </>
        ) : (
          <CardContent>
            <CardDescription>{children}</CardDescription>
          </CardContent>
        )}
        {!skeleton && eventGroup !== "platform" && (
          <CardFooter>
            {history.executedIn}: {executedAt && dateToString(executedAt)}
          </CardFooter>
        )}
      </S.Wrapper>
    </Component>
  )
}
