import styled from "styled-components"
import { media } from "../../../../../../utils/media-queries"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;

  padding: 12px;

  border: 1px solid ${({ theme }) => theme.palette.gray[400]};
  border-radius: 4px;

  width: 100%;

  input {
    color: transparent;
    overflow: hidden;
    width: 130px;

    @media ${media.small} {
      color: unset;
      overflow: unset;
      width: unset;
    }
  }
`

export const IconBackground = styled.div<{ $backgroundColor?: string }>`
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  height: 48px;
  width: 48px;
  border-radius: 50%;
  display: none;

  padding-bottom: 2px;

  @media ${media.small} {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
