import styled from "styled-components"

export const CardHeader = styled.div`
  align-items: center;
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
export const CardExpand = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: ${(props) => props.theme.palette.gray[700]};
  &:hover {
    color: ${(props) => props.theme.palette.gray[500]};
  }
`
