import { useEffect, useState } from "react"
import { useAppTranslate } from "../../../../../../translate/useAppTranslate"
import { MessageContainer } from "../../styles"
import { HistoryCardSkeleton } from "../Skeleton/HistoryCard"
import { HistoryContent } from "./components/HistoryContent"
import { HistoryHeader } from "./components/HistoryHeader"
import { Container, Wrapper } from "./styles"
import { HistoryFooter } from "./components/HistoryFooter"
import { ModalEvent } from "./components/ModalEvent"
import { OrderingEvent } from "../../../../../../store/api/assets/entities"
import {
  useFetchAssetDetailsQuery,
  useFetchAssetHistoryQuery,
} from "../../../../../../store/store"
import { EventPlatformType, EventUserType } from "../../../utils/eventType"

interface HistoryCardProps {
  assetId: string
}

export const HistoryCard = ({ assetId }: HistoryCardProps) => {
  const { assets } = useAppTranslate()

  const {
    data,
    isError,
    isFetching: isFetchingAssets,
  } = useFetchAssetDetailsQuery(assetId)
  const [page, setPage] = useState(1)
  const [offset, setOffset] = useState(0)
  const [orderBy, setOrderBy] = useState<"created_at" | "executed_at">(
    "created_at"
  )
  const [sortBy, setSortBy] = useState<"DESC" | "ASC">("DESC")
  const [filter, setFilter] = useState<string>(
    [...Object.values(EventUserType), ...Object.values(EventPlatformType)].join(
      ","
    )
  )
  const [recursive, setRecursive] = useState(true)

  const [openCreateEventModal, setOpenCreateEventModal] = useState(false)
  const limit = 10

  const { data: historyData, isFetching: isFetchingHistory } =
    useFetchAssetHistoryQuery({
      assetId,
      limit,
      offset,
      orderBy: orderBy,
      sortBy: sortBy,
      search: filter,
      recursive: recursive,
    })

  useEffect(() => {
    setPage(1)
    setOffset(0)
  }, [assetId])

  const handleChangePage = (newPage: number) => {
    setPage(newPage)
    setOffset(limit * (newPage - 1))
  }

  const handleChangeOrder = (ordering: OrderingEvent) => {
    switch (ordering) {
      case "Created (DESC)":
        setSortBy("DESC")
        setOrderBy("created_at")
        break
      case "Created (ASC)":
        setSortBy("ASC")
        setOrderBy("created_at")
        break
      case "Executed (DESC)":
        setSortBy("DESC")
        setOrderBy("executed_at")
        break
      case "Executed (ASC)":
        setSortBy("ASC")
        setOrderBy("executed_at")
        break
    }

    setPage(1)
    setOffset(0)
  }

  const handleFilterEvents = (search: string) => {
    setFilter(search)
    setPage(1)
    setOffset(0)
  }

  const handleRecursive = (recursive: boolean) => {
    setRecursive(recursive)
    setPage(1)
    setOffset(0)
  }

  if (isFetchingAssets || isFetchingHistory) return <HistoryCardSkeleton />

  if (isError || !data || !historyData)
    return <MessageContainer>{assets.history.error}</MessageContainer>

  return (
    <Container>
      <HistoryHeader
        handleOpenCreateEventModal={() => setOpenCreateEventModal(true)}
        handleChangeOrdering={handleChangeOrder}
        handleChangeFilter={handleFilterEvents}
        handleChangeRecursive={handleRecursive}
        filter={filter}
        isRecursive={recursive}
      />
      {data && (
        <>
          <Wrapper>
            <HistoryContent historyData={historyData.data} assetId={assetId} />
          </Wrapper>
          <HistoryFooter
            total={historyData.total}
            itemsPerPage={limit}
            page={page}
            onChangePage={handleChangePage}
          />
        </>
      )}

      {historyData.data.length === 0 && (
        <Wrapper>
          <MessageContainer>
            {assets.history.assetHasNoRegisteredEvents}
          </MessageContainer>
        </Wrapper>
      )}

      {openCreateEventModal && (
        <ModalEvent
          handleCloseModal={() => setOpenCreateEventModal(false)}
          assetId={assetId}
          assetName={data?.name || ""}
          edit={true}
          typeModal='create'
        />
      )}
    </Container>
  )
}
