import { useMemo } from "react"
import { toast } from "react-toastify"
import { useForm, useWatch } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { SCHEMA_ERRORS } from "../../utils/schema"
import * as z from "zod"
import { useAppTranslate } from "../../../../../translate/useAppTranslate"
import { useLinkSensorMutation } from "../../../../../store/store"

const schema = z.object({
  linkedAt: z.string().trim().min(1, SCHEMA_ERRORS.REQUIRED),
  sensorId: z.number().min(1, SCHEMA_ERRORS.REQUIRED),
})

type ConfigureSensorSchema = z.infer<typeof schema>

interface ConfigureSensorProps {
  assetId: string
  handleCloseModal: () => void
}

export const useConfigureSensor = ({
  assetId,
  handleCloseModal,
}: ConfigureSensorProps) => {
  const { assets } = useAppTranslate()

  const [updateSensor, mutation] = useLinkSensorMutation()

  const isDisable = useMemo(() => mutation.isLoading, [mutation.isLoading])

  const {
    control,
    setValue,
    register,
    handleSubmit: _handleSubmit,
    formState: { errors },
  } = useForm<ConfigureSensorSchema>({
    resolver: zodResolver(schema),
  })

  const handleSelectSensor = (id: number) => {
    setValue("sensorId", id)
  }

  const date = useWatch({
    control,
    name: "linkedAt",
  })

  const handleConfigureSensor = ({
    sensorId,
    linkedAt,
  }: ConfigureSensorSchema) => {
    updateSensor({
      assetId,
      sensorId,
      linkedAt: new Date(linkedAt).toISOString(),
    })
      .unwrap()
      .then(() => {
        toast.success(assets.sensors.linkSensorSuccess)
        handleCloseModal()
      })
      .catch(() => {
        toast.error(assets.form.sensors.error)
      })
  }

  return {
    handleSubmit: _handleSubmit(handleConfigureSensor),
    register,
    handleSelectSensor,
    isDisable,
    isEmpty: !date,
    errors,
  }
}
