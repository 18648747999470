import * as z from "zod"
import { AssetDetails } from "../entities/AssetDetails"

export const enum SCHEMA_ERRORS {
  REQUIRED = "REQUIRED",
}

export const assetSchema = z
  .object({
    name: z.string().trim().min(1, SCHEMA_ERRORS.REQUIRED),
    type: z.string().trim().min(1, SCHEMA_ERRORS.REQUIRED),
    description: z.string().trim().min(1, SCHEMA_ERRORS.REQUIRED),
    properties: z.array(
      z.object<{
        required: z.ZodBoolean
        property: z.ZodString
        value: z.ZodString
      }>({
        required: z.boolean(),
        property: z.string(),
        value: z.string(),
      })
    ),
    sensorId: z.number().optional(),
    linkedAt: z.string(),
  })
  .superRefine(({ sensorId, linkedAt, properties }, ctx) => {
    if (linkedAt && !sensorId) {
      ctx.addIssue({
        message: SCHEMA_ERRORS.REQUIRED,
        code: "too_small",
        path: ["sensorId"],
        minimum: 1,
        type: "string",
        inclusive: true,
      })
      return false
    }

    if (sensorId && !linkedAt) {
      ctx.addIssue({
        message: SCHEMA_ERRORS.REQUIRED,
        code: "too_small",
        path: ["linkedAt"],
        minimum: 1,
        type: "string",
        inclusive: true,
      })
      return false
    }

    properties.forEach((property, index) => {
      if (property.required && !property.value) {
        ctx.addIssue({
          message: SCHEMA_ERRORS.REQUIRED,
          code: "too_small",
          path: ["properties", index, "value"],
          minimum: 1,
          type: "string",
          inclusive: true,
        })

        return false
      }
    })
  })

export type AssetSchema = z.infer<typeof assetSchema>

export const defaultValues: AssetSchema = {
  name: "",
  type: "",
  description: "",
  properties: [],
  sensorId: undefined,
  linkedAt: "",
}

export const getDefaultValues = ({
  name,
  assetType,
  description,
  propertyValues,
}: AssetDetails): AssetSchema => {
  return {
    name,
    type: assetType.id.toString(),
    description,
    properties: Object.keys(propertyValues).map((key) => {
      return {
        required: assetType.properties[key].required,
        property: key,
        value: propertyValues[key],
      }
    }),
    linkedAt: "",
  }
}

export const formatDate = (milliseconds: number): string => {
  const date = new Date(milliseconds)

  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, "0")
  const day = String(date.getDate()).padStart(2, "0")
  const hours = String(date.getHours()).padStart(2, "0")
  const minutes = String(date.getMinutes()).padStart(2, "0")

  return `${year}-${month}-${day}T${hours}:${minutes}`
}

export const enum SCHEMA_ERRORS_EVENT {
  TITLE = "TITLE",
  DESCRIPTION = "DESCRIPTION",
  TYPE = "TYPE",
  EXECUTED_AT = "EXECUTED_AT",
}

export const eventSchema = z.object({
  title: z.string().trim().min(1, SCHEMA_ERRORS_EVENT.TITLE),
  eventType: z.string().trim().min(1, SCHEMA_ERRORS_EVENT.TYPE),
  description: z.string().trim().min(1, SCHEMA_ERRORS_EVENT.DESCRIPTION),
  executedAt: z.string().min(1, SCHEMA_ERRORS_EVENT.EXECUTED_AT),
})

export type EventSchema = z.infer<typeof eventSchema>
