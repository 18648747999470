import { Lightning, Thermometer } from "phosphor-react"
import { Container, TooltipWrapper, Tooltip, Wrapper } from "./styles"
import { HDR_SERVICES_TYPE } from "hdr-process-data"
import { Text } from "../ui/Text"

import RMS2 from "../../assets/services/RMS2.png"
import RMS2_GRAY from "../../assets/services/RMS2_GRAY.png"

import RMMS from "../../assets/services/RMMS.png"
import RMMS_GRAY from "../../assets/services/RMMS_GRAY.png"

import POT from "../../assets/services/POT.png"
import POT_GRAY from "../../assets/services/POT_GRAY.png"

import TILT from "../../assets/services/TILT.png"
import TILT_GRAY from "../../assets/services/TILT_GRAY.png"

import FFT from "../../assets/services/FFT.png"
import FFT_GRAY from "../../assets/services/FFT_GRAY.png"

import ACCRAW from "../../assets/services/ACCRAW.png"
import ACCRAW_GRAY from "../../assets/services/ACCRAW_GRAY.png"

import _4A20 from "../../assets/services/4A20.png"
import _4A20_GRAY from "../../assets/services/4A20_GRAY.png"

import NTC from "../../assets/services/NTC.png"
import NTC_GRAY from "../../assets/services/NTC_GRAY.png"
import { useHDRService } from "../../utils/hdr_services_name"

const Icon = ({
  type,
  isConfigured,
}: {
  type: HDR_SERVICES_TYPE
  isConfigured: boolean
}) => {
  switch (type) {
    case HDR_SERVICES_TYPE.temp:
      return <Thermometer size={30} weight='light' />
    case HDR_SERVICES_TYPE.rms2:
      return (
        <img src={isConfigured ? RMS2 : RMS2_GRAY} alt='RMS2' height={30} />
      )
    case HDR_SERVICES_TYPE.rmms:
      return (
        <img src={isConfigured ? RMMS : RMMS_GRAY} alt='RMMS' height={30} />
      )
    case HDR_SERVICES_TYPE.tilt:
      return (
        <img src={isConfigured ? TILT : TILT_GRAY} alt='TILT' height={30} />
      )
    case HDR_SERVICES_TYPE.fft:
      return <img src={isConfigured ? FFT : FFT_GRAY} alt='FFT' height={27} />
    case HDR_SERVICES_TYPE.accRaw:
      return (
        <img
          src={isConfigured ? ACCRAW : ACCRAW_GRAY}
          alt='ACCRAW'
          height={27}
        />
      )
    case HDR_SERVICES_TYPE._4t20:
      return (
        <img src={isConfigured ? _4A20 : _4A20_GRAY} alt='4A20' height={20} />
      )
    case HDR_SERVICES_TYPE.ntc:
      return <img src={isConfigured ? NTC : NTC_GRAY} alt='NTC' height={28} />
    case HDR_SERVICES_TYPE.pot:
      return <img src={isConfigured ? POT : POT_GRAY} alt='POT' height={12} />
    default:
      return <Lightning />
  }
}

interface IconServiceProps {
  type: HDR_SERVICES_TYPE
  isConfigured: boolean
}

export const IconService = ({
  type,
  isConfigured = false,
}: IconServiceProps) => {
  const { getServiceHelpText } = useHDRService()

  return (
    <Wrapper>
      <Container $isConfigured={isConfigured}>
        <Icon type={type} isConfigured={isConfigured} />
      </Container>
      <TooltipWrapper>
        <Tooltip>
          <Text>{getServiceHelpText({ type })}</Text>
        </Tooltip>
      </TooltipWrapper>
    </Wrapper>
  )
}
