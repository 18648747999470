import { createContext, ReactNode, useState } from "react"
import { TreeItem } from "react-complex-tree"
import { HDR_SERVICES_TYPE } from "hdr-process-data"
import { ChartType } from "../../Chart/entities/ChartData"
import { Asset } from "../components/AssetsTable"
import {
  ChartConfig,
  AssetChartDetails,
  Service,
} from "../../../store/api/analytics/entities"
import {
  ServiceConfigured,
  ServiceProperties,
} from "../../../utils/entities/assets/ServiceConfigured"
import { AssetData } from "../../../utils/entities/assets/AssetTreeData"

export interface AssetsContextDTO {
  assetsList: Asset[]
  hideHealthService: boolean
  chartType?: ChartType
  hasAxes: boolean
  canEditPath: boolean
  getServiceState: (
    assetId: string,
    type: HDR_SERVICES_TYPE,
    configsCount: number
  ) => "true" | "false" | "indeterminate"
  getAxlesState: (assetId: string, type: HDR_SERVICES_TYPE) => ChartConfig[]
  getServiceAxes: (
    isDisabled: boolean,
    type: HDR_SERVICES_TYPE,
    properties?: ServiceProperties
  ) => ChartConfig[]
  handleChangeSelectionCheckbox: (checked: boolean) => void
  handleIsServiceDisabled(service: ServiceConfigured): boolean
  handleInsertInAssetList: (item: TreeItem<AssetData>) => void
  handleRemoveInAssetList: (assetId: string) => void
  handleServiceCheckbox: (
    assetId: string,
    type: HDR_SERVICES_TYPE,
    configs: ChartConfig[],
    value: boolean
  ) => void
  handleAxleCheckbox: (
    assetId: string,
    type: HDR_SERVICES_TYPE,
    axle: string,
    value: boolean
  ) => void
  handleChangePath: (assetId: string, path: string) => void
  getSelectionCheckboxState: () => "true" | "false" | "indeterminate"
}

export const AssetsContext = createContext<AssetsContextDTO | null>(null)

interface AssetsContextProviderProps {
  children: ReactNode
  selectedAssets: AssetChartDetails[]
  handleChangeSelectedAssets(assets: AssetChartDetails[]): void
  hideHealthService?: boolean
  chartType?: ChartType
  hasAxes?: boolean
  canEditPath: boolean
  handleIsServiceDisabled(service: ServiceConfigured): boolean
}

export const AssetsContextProvider = ({
  children,
  selectedAssets,
  handleChangeSelectedAssets,
  hideHealthService = false,
  chartType,
  hasAxes = false,
  canEditPath,
  handleIsServiceDisabled,
}: AssetsContextProviderProps) => {
  const [assetsList, setAssetsList] = useState<Asset[]>([])

  const getServiceAxes = (
    isDisabled: boolean,
    type: HDR_SERVICES_TYPE,
    properties?: ServiceProperties
  ): ChartConfig[] => {
    if (isDisabled) return []

    if (type === HDR_SERVICES_TYPE.tilt) return ["PITCH", "ROLL"]

    if (!properties) return []

    if (properties.axis === "All") return ["X", "Y", "Z", "MODULE"]
    if (properties.axis) return [properties.axis] as ChartConfig[]

    if (properties.channel === "AB") return ["A", "B"]
    if (properties.channel) return [properties.channel] as ChartConfig[]

    return []
  }

  const handleChangeSelectionCheckbox = (checked: boolean) => {
    if (!checked) {
      selectedAssets.forEach((asset) => (asset.services = {}))
      handleChangeSelectedAssets([...selectedAssets])
      return
    }

    for (let i = 0; i < assetsList.length; i++) {
      const asset = assetsList[i]
      const selected = selectedAssets.find(
        (selectedAsset) => selectedAsset.id === asset.id
      )

      if (!selected) continue

      const selectedServices = selected.services as Record<
        HDR_SERVICES_TYPE,
        Service
      >

      if (!hideHealthService)
        selectedServices[HDR_SERVICES_TYPE.health] = { configs: [] }

      for (let j = 0; j < asset.services.length; j++) {
        const service = asset.services[j]

        if (!service.isConfigured) continue

        if (
          chartType &&
          chartType === ChartType.TWO &&
          service.type !== HDR_SERVICES_TYPE.fft
        )
          continue

        if (
          chartType &&
          chartType === ChartType.THREE &&
          service.type !== HDR_SERVICES_TYPE.accRaw
        )
          continue

        selectedServices[service.type] = {
          configs: getServiceAxes(
            !service.isConfigured,
            service.type,
            service.properties
          ),
        }
      }
    }

    handleChangeSelectedAssets([...selectedAssets])
  }

  const getSelectionCheckboxState = (): "true" | "false" | "indeterminate" => {
    let hasOneService = false

    for (let i = 0; i < assetsList.length; i++) {
      const asset = assetsList[i]
      const selected = selectedAssets.find(
        (selectedAsset) => selectedAsset.id === asset.id
      )

      if (!selected) continue

      const services = Object.keys(selected.services)

      for (let j = 0; j < asset.services.length; j++) {
        const service = asset.services[j]

        if (!service.isConfigured) continue

        if (
          chartType &&
          chartType === ChartType.TWO &&
          service.type !== HDR_SERVICES_TYPE.fft
        )
          continue

        if (
          chartType &&
          chartType === ChartType.THREE &&
          service.type !== HDR_SERVICES_TYPE.accRaw
        )
          continue

        if (services.includes(service.type.toString())) {
          hasOneService = true
        } else {
          if (hasOneService) {
            return "indeterminate"
          }
        }
      }

      if (hideHealthService) continue

      if (services.includes("0")) {
        hasOneService = true
      } else {
        if (hasOneService) return "indeterminate"
      }
    }

    return hasOneService ? "true" : "false"
  }

  const handleServiceCheckbox = (
    assetId: string,
    type: HDR_SERVICES_TYPE,
    configs: ChartConfig[],
    value: boolean
  ) => {
    const asset = selectedAssets.find((asset) => asset.id === assetId)
    if (!asset) return

    const services = asset.services as Record<HDR_SERVICES_TYPE, Service>

    if (value && services[type]) services[type].configs = configs

    if (value && !services[type])
      services[type] = {
        configs,
      }

    if (!value) delete services[type]

    handleChangeSelectedAssets([...selectedAssets])
  }

  const handleAxleCheckbox = (
    assetId: string,
    type: HDR_SERVICES_TYPE,
    axle: string,
    value: boolean
  ) => {
    const asset = selectedAssets.find((asset) => asset.id === assetId)
    if (!asset) return

    const services = asset.services as Record<HDR_SERVICES_TYPE, Service>
    const service = services[type]

    if (value && service) {
      const findAxle = service.configs.find((config) => config === axle)

      if (!findAxle) {
        service.configs.push(axle as ChartConfig)
      }
    }

    if (value && !service) {
      services[type] = { configs: [axle as ChartConfig] }
    }

    if (!value) {
      service.configs = service.configs.filter((config) => config !== axle)

      if (service.configs.length === 0) delete services[type]
    }

    handleChangeSelectedAssets([...selectedAssets])
  }

  const getServiceState = (
    assetId: string,
    type: HDR_SERVICES_TYPE,
    configsCount: number
  ): "true" | "false" | "indeterminate" => {
    const asset = selectedAssets.find((asset) => asset.id === assetId)
    if (!asset) return "false"

    const service = (asset.services as Record<HDR_SERVICES_TYPE, Service>)[type]

    if (!service) return "false"

    return service.configs.length === configsCount ? "true" : "indeterminate"
  }

  const getAxlesState = (
    assetId: string,
    type: HDR_SERVICES_TYPE
  ): ChartConfig[] => {
    const asset = selectedAssets.find((asset) => asset.id === assetId)
    if (!asset) return []

    const service = (asset.services as Record<HDR_SERVICES_TYPE, Service>)[type]
    if (!service) return []

    return service.configs
  }

  const handleInsertInAssetList = (item: TreeItem<AssetData>) => {
    setAssetsList((prev) => [
      ...prev,
      {
        id: item.data.id,
        name: item.data.name,
        services: item.data.services ?? [],
        path: item.data.path ?? "",
      },
    ])

    handleChangeSelectedAssets([
      ...selectedAssets,
      {
        id: item.data.id,
        services: {},
        path: item.data.path ?? "",
      },
    ])
  }

  const handleRemoveInAssetList = (id: string) => {
    setAssetsList((prev) => prev.filter((asset) => asset.id !== id))
    handleChangeSelectedAssets(
      selectedAssets.filter((asset) => asset.id !== id)
    )
  }

  const handleChangePath = (assetId: string, path: string) => {
    const asset = selectedAssets.find((asset) => asset.id === assetId)
    if (!asset) return

    asset.path = path

    handleChangeSelectedAssets([...selectedAssets])
  }

  const defaultContext: AssetsContextDTO = {
    assetsList,
    hideHealthService,
    chartType,
    hasAxes,
    canEditPath,
    handleChangeSelectionCheckbox,
    handleIsServiceDisabled,
    handleInsertInAssetList,
    handleRemoveInAssetList,
    handleServiceCheckbox,
    handleAxleCheckbox,
    handleChangePath,
    getServiceState,
    getAxlesState,
    getSelectionCheckboxState,
    getServiceAxes,
  }

  return (
    <AssetsContext.Provider value={defaultContext}>
      {children}
    </AssetsContext.Provider>
  )
}
