/* eslint-disable @typescript-eslint/no-explicit-any */
import { DatetimeInput } from "../../../../components/ui/DatetimeInput"
import { Input } from "../../../../components/ui/Input"
import { InputSelect } from "../../../../components/ui/InputSelect"
import { TextArea } from "../../../../components/ui/TextArea"
import { Container } from "./styles"
import { FieldErrors, UseFormRegister } from "react-hook-form"
import { useAppTranslate } from "../../../../translate/useAppTranslate"
import { SCHEMA_ERRORS_EVENT } from "../utils/schema"
import { EventPlatformType, EventUserType, EventAll } from "../utils/eventType"

interface EventHistoyProps {
  defaultTitle: string
  defaultDescription: string
  defaultType: string
  defaultExecutedAt: string
  typeModal: "edit" | "create" | "view"
  register: UseFormRegister<any>
  errors: FieldErrors<any>
}

export const EventHistoy = ({
  defaultTitle,
  defaultDescription,
  defaultType,
  defaultExecutedAt,
  typeModal,
  register,
  errors,
}: EventHistoyProps) => {
  const {
    assets: { history },
  } = useAppTranslate()

  const isDisabled = typeModal === "view"

  const eventDescriptions = {
    [EventUserType.MAINTENANCE_CORRECTIVE]:
      history.correctiveMaintenanceShutdown,
    [EventUserType.PLANNED_STOP]: history.scheduledShutdown,
    [EventUserType.MAINTENANCE_PREVENTIVE]:
      history.preventiveMaintenanceShutdown,
    [EventUserType.EQUIPMENT_FAILURE]: history.equipmentFailure,
    [EventUserType.OPERATIONAL_STOP]: history.operationalShutdown,

    [EventPlatformType.ASSET_CREATED]: history.assetCreated,
    [EventPlatformType.ASSET_UPDATED]: history.assetUpdated,
    [EventPlatformType.ASSET_DELETED]: history.assetDeleted,
    [EventPlatformType.ASSET_MOVED_TO_NEW_PARENT]:
      history.assetMovedToNewParent,
    [EventPlatformType.SENSOR_LINKED_TO_ASSET]: history.sensorLinkedToAsset,
    [EventPlatformType.SENSOR_UNLINKED_FROM_ASSET]:
      history.sensorUnlinkedFromAsset,
    [EventUserType.INSPECTION]: history.inspection,
    [EventUserType.OTHER]: history.other,
  }

  const OptionsType =
    typeModal === "view"
      ? [...Object.values(EventUserType), ...Object.values(EventPlatformType)]
      : Object.values(EventUserType)

  const getErrorMessage = (message: string | undefined): string | undefined => {
    if (!message) return undefined

    switch (message) {
      case SCHEMA_ERRORS_EVENT.TITLE:
        return history.titleIsRequired
      case SCHEMA_ERRORS_EVENT.DESCRIPTION:
        return history.descriptionIsRequired
      case SCHEMA_ERRORS_EVENT.EXECUTED_AT:
        return history.executionDateIsRequired
      case SCHEMA_ERRORS_EVENT.TYPE:
        return history.typeIsRequired
      default:
        return history.requiredField
    }
  }

  return (
    <Container>
      <Input
        label={history.titleName}
        placeholder={history.enterTheEventTitle}
        defaultValue={defaultTitle}
        disabled={isDisabled}
        error={getErrorMessage(errors.title?.message as string)}
        {...register("title")}
      />
      <InputSelect
        label={history.type}
        placeholder={history.selectTheEventType}
        options={OptionsType}
        defaultValue={defaultType}
        disabled={isDisabled}
        renderLabel={(option) => eventDescriptions[option as EventAll]}
        {...register("eventType")}
        error={getErrorMessage(errors.eventType?.message as string)}
      />
      <TextArea
        defaultValue={defaultDescription}
        label={history.description}
        rows={10}
        placeholder={history.enterTheEventDescription}
        disabled={isDisabled}
        {...register("description")}
        error={getErrorMessage(errors.description?.message as string)}
      />
      <DatetimeInput
        label={history.executionDate}
        isEmpty={false}
        type='datetime-local'
        placeholder={history.enterTheExecutionDate}
        disabled={isDisabled}
        defaultValue={defaultExecutedAt}
        {...register("executedAt")}
        error={getErrorMessage(errors.executedAt?.message as string)}
      />
    </Container>
  )
}
