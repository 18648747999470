import styled from "styled-components"

export const Container = styled.footer`
  background-color: ${({ theme }) => theme.palette.gray[50]};
  position: sticky;
  bottom: 0;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: auto;
  align-items: center;
  height: auto;
  z-index: 10;

  .pagination {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 16px;
    width: 100%;
  }
`
export const Pages = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: min-content;
`

export const Counter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: min-content;
  gap: 2px;
  margin-left: 4px;
  margin-right: 4px;
`

export const Item = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: default;
  color: ${({ theme }) => theme.palette.gray[500]};
  font-family: ${(props) => props.theme.typography.fontFamily};
  font-size: ${(props) => props.theme.typography.text.sm.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  line-height: ${(props) => props.theme.typography.text.sm.lineHeight};
`

export const Page = styled(Item)<{ $selected?: boolean }>`
  cursor: pointer;

  color: ${({ $selected = false, theme }) =>
    $selected ? theme.palette.gray[700] : theme.palette.gray[500]};
`
