import { MutationLifecycleApi } from "@reduxjs/toolkit/dist/query/endpointDefinitions"
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/query"
import { analyticsApi } from "./slice"
import {
  CreateAssetAnalyticRequest,
  CreateSensorAnalyticRequest,
  DeleteAnalyticRequest,
} from "./entities"
import { ChartData } from "../../../components/Chart/entities/ChartData"

type BaseQueryFnType = BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  {},
  FetchBaseQueryMeta
>

type MutationType<Request, Response> = MutationLifecycleApi<
  Request,
  BaseQueryFnType,
  Response,
  "analyticsApi"
>

export const onCreateAnalyticQueryStarted = async (
  request: CreateSensorAnalyticRequest | CreateAssetAnalyticRequest,
  {
    dispatch,
    queryFulfilled,
  }: MutationType<
    CreateSensorAnalyticRequest | CreateAssetAnalyticRequest,
    ChartData
  >
) => {
  try {
    const response = (await queryFulfilled).data

    dispatch(
      analyticsApi.util.updateQueryData(
        "fetchAnalytics",
        { dashboardId: request.dashboardId },
        (draft) => {
          draft.push(response)
        }
      )
    )
  } catch (error) {
    console.error(error)
  }
}

export const onDeleteAnalyticQueryStarted = async (
  request: DeleteAnalyticRequest,
  { dispatch, queryFulfilled }: MutationType<DeleteAnalyticRequest, void>
) => {
  try {
    await queryFulfilled

    dispatch(
      analyticsApi.util.updateQueryData(
        "fetchAnalytics",
        { dashboardId: request.dashboardId },
        (draft) => {
          const index = draft.findIndex(
            (chart) => chart.id.toString() === request.analyticId
          )

          if (index !== -1) draft.splice(index, 1)
        }
      )
    )
  } catch (error) {
    console.error(error)
  }
}
