import { useContext } from "react"
import { AssetsTree } from "../ui/AssetsTree"
import { Text } from "../ui/Text"
import { Content, TitleContainer, TreeContainer } from "./styles"
import { TreeItem } from "react-complex-tree"
import { useAppTranslate } from "../../translate/useAppTranslate"
import { ChartType } from "../Chart/entities/ChartData"
import {
  AssetsContext,
  AssetsContextDTO,
  AssetsContextProvider,
} from "./context/assetsContext"
import { AssetsTable } from "./components/AssetsTable"
import { AssetChartDetails } from "../../store/api/analytics/entities"
import { ServiceConfigured } from "../../utils/entities/assets/ServiceConfigured"
import { AssetData } from "../../utils/entities/assets/AssetTreeData"

interface ConfigureAssetsProps {
  handleChangeSelectedAssets(assets: AssetChartDetails[]): void
  handleIsServiceDisabled(service: ServiceConfigured): boolean
  selectedAssets: AssetChartDetails[]
  hideHealthService?: boolean
  hasAxes?: boolean
  chartType?: ChartType
  canEditPath?: boolean
}

export const ConfigureAssets = ({
  handleChangeSelectedAssets,
  handleIsServiceDisabled,
  selectedAssets,
  hideHealthService = false,
  hasAxes = false,
  canEditPath = false,
  chartType,
}: ConfigureAssetsProps) => {
  return (
    <AssetsContextProvider
      selectedAssets={selectedAssets}
      handleChangeSelectedAssets={handleChangeSelectedAssets}
      handleIsServiceDisabled={handleIsServiceDisabled}
      chartType={chartType}
      hideHealthService={hideHealthService}
      hasAxes={hasAxes}
      canEditPath={canEditPath}
    >
      <Wrapper />
    </AssetsContextProvider>
  )
}

const Wrapper = () => {
  const {
    assets: { tree },
  } = useAppTranslate()

  const { assetsList, hasAxes, handleInsertInAssetList } = useContext(
    AssetsContext
  ) as AssetsContextDTO

  const showAddIcon = (item: TreeItem<AssetData>) => {
    const alreadyAdded = assetsList.find((asset) => asset.id === item.data.id)

    return (
      item.data.services !== undefined &&
      item.data.services.length > 0 &&
      !alreadyAdded
    )
  }

  return (
    <Content>
      <TreeContainer>
        <TitleContainer>
          <Text fontWeight='semibold' color='gray.800'>
            {tree.title}
          </Text>
        </TitleContainer>

        <AssetsTree
          showAddIcon={showAddIcon}
          onClickAddIcon={handleInsertInAssetList}
          services
        />
      </TreeContainer>

      <AssetsTable hasAxes={hasAxes} />
    </Content>
  )
}
