import { Column, Table } from "../../../../../components/Table"
import { Checkbox } from "../../../../../components/ui/Checkbox"
import { FlexContainer } from "../../../../../components/ui/FlexContainer"
import { Text } from "../../../../../components/ui/Text"
import { useFetchProvisionedSensorsQuery } from "../../../../../store/store"
import { Sensor } from "../../../../../store/api/sensors/entities"
import { useContext, useState } from "react"
import { ReportsContext, ReportsContextDTO } from "../../context/ReportsContext"
import { TableSkeleton } from "../../../../../components/TableSkeleton"
import { ErrorMessage } from "../../../../../components/ui/ErrorMessage"
import { useAppTranslate } from "../../../../../translate/useAppTranslate"
import { filterSensors } from "../../utils/filterSensors"
import { servicesTypes } from "../../../../../store/api/utils"
import { useHDRService } from "../../../../../utils/hdr_services_name"
import {
  DisabledContainer,
  ServiceContainer,
  ServicesWrapper,
  ServiceWrapper,
  TextWrapper,
  Tooltip,
  TooltipWrapper,
} from "./styles"
import { cleanAndPadSerialNumber } from "../../../../../utils/cleanAndPadSerialNumber"
import { formatedDate } from "../../../../../utils/date"
import { SensorsSelectionChexbox } from "../SensorsSelectionChexbox"

export const SensorTable = () => {
  const { reports } = useAppTranslate()

  const {
    handleCheckbox,
    handleChangeCheckbox,
    getCheckboxState,
    getServiceCheckbox,
    search,
    selectedServices,
  } = useContext(ReportsContext) as ReportsContextDTO

  const { getHDRServiceAcronym } = useHDRService()

  const { data, isLoading, isError, refetch } =
    useFetchProvisionedSensorsQuery()

  const [orderBy, setOrderBy] = useState<"name" | "serial_number">("name")
  const [sortBy, setSortBy] = useState<"ASC" | "DESC">("ASC")

  const handleChangeOrderBy = (orderBy: string) => {
    setOrderBy(orderBy as "name" | "serial_number")
    setSortBy((state) => (state === "ASC" ? "DESC" : "ASC"))
  }

  const getSensorName = (sensor: Sensor) => {
    if (sensor.name) return sensor.name
    return `S${cleanAndPadSerialNumber(sensor.serialNumber)}`
  }

  const columns: Column<Sensor>[] = [
    {
      id: "name",
      label: reports.table.name,
      order: true,
      render: (sensor) => (
        <TextWrapper>
          <Text>{getSensorName(sensor)}</Text>
        </TextWrapper>
      ),
    },
    {
      id: "serial_number",
      label: reports.table.serialNumber,
      order: true,
      render: ({ serialNumber }) => (
        <TextWrapper>
          <Text>{serialNumber}</Text>
        </TextWrapper>
      ),
    },
    {
      id: "services",
      label: reports.table.services,
      icon: (
        <SensorsSelectionChexbox
          data={data}
          getServiceCheckbox={getServiceCheckbox}
          handleChangeCheckbox={handleChangeCheckbox}
        />
      ),
      render: (sensor) => {
        return (
          <ServicesWrapper>
            <FlexContainer
              direction='row'
              justify='start'
              gap={24}
              fullWidth
              fullHeight
            >
              {servicesTypes.map((serviceType, index) => {
                const serviceInfo = sensor.services.find(
                  (s) => s.type === serviceType
                )

                if (serviceInfo) {
                  return (
                    <ServiceContainer key={serviceType}>
                      <Checkbox
                        disabled={serviceInfo.disabled}
                        checked={getCheckboxState(sensor, serviceInfo.name)}
                        onChange={(e) =>
                          handleCheckbox(
                            sensor,
                            serviceInfo.name,
                            e.target.checked
                          )
                        }
                      />
                      <ServiceWrapper
                        $padding={index !== servicesTypes.length - 1}
                      >
                        <Text
                          color={serviceInfo.disabled ? "gray.400" : "gray.600"}
                        >
                          {getHDRServiceAcronym(serviceType).toUpperCase()}
                        </Text>
                      </ServiceWrapper>

                      {!serviceInfo.disabled && (
                        <TooltipWrapper className='tooltip'>
                          <Tooltip>
                            <Text>{`${reports.lastData}: ${formatedDate(serviceInfo.lastData ?? "")}`}</Text>
                          </Tooltip>
                        </TooltipWrapper>
                      )}
                    </ServiceContainer>
                  )
                }

                return (
                  <DisabledContainer key={serviceType}>
                    <Checkbox disabled={true} />

                    <ServiceWrapper
                      $padding={index !== servicesTypes.length - 1}
                    >
                      <Text color='alert.darker'>
                        {getHDRServiceAcronym(serviceType).toUpperCase()}
                      </Text>
                    </ServiceWrapper>
                  </DisabledContainer>
                )
              })}
            </FlexContainer>
          </ServicesWrapper>
        )
      },
    },
  ]

  if (isLoading) return <TableSkeleton columns={columns} data={2} />

  if (isError || !data)
    return <ErrorMessage message={reports.error} handleReload={refetch} />

  const compare = (a: string, b: string, sortBy: "ASC" | "DESC") => {
    if (sortBy === "ASC") {
      return a > b ? 1 : -1
    } else {
      return a < b ? 1 : -1
    }
  }

  const sensors = data
    .filter((e) => filterSensors(e, search, selectedServices))
    .sort((a, b) => {
      switch (orderBy) {
        case "name":
          return compare(a.name, b.name, sortBy)
        case "serial_number":
          return compare(a.serialNumber, b.serialNumber, sortBy)
      }

      return 0
    })

  return (
    <Table
      columns={columns}
      data={sensors}
      total={0}
      itemsPerPage={0}
      page={0}
      sortAsc={sortBy === "ASC"}
      orderBy={orderBy}
      onChangeItemsPerPage={() => {}}
      onChangePage={() => {}}
      onChangeOrderBy={handleChangeOrderBy}
      emptyMessage={reports.table.empty}
      onCheck={() => {}}
    />
  )
}
