import { HDR_SERVICES_TYPE } from "hdr-process-data"
import { Axis } from "../../../utils/entities/Axis"
import { AssetChartDetails } from "../../../store/api/analytics/entities"
import { ExtraConfig } from "../../../pages/Dashboard/entities/Analytics"

export interface ChartData {
  /**Chart ID */
  id: string
  orientedBy: OrientedBy
  /**Dashboard ID */
  dashboardId: number
  /**Chart Title */
  title: string
  /**Chart Type */
  type: ChartType
  /**Chart Period */
  period: number
  /**List of service that will add to chart */
  services?: Service[]
  /**List of assets that will add to chart */
  assets?: AssetChartDetails[]
}

export enum OrientedBy {
  SENSOR = 1,
  ASSET = 2,
}

export enum ChartType {
  ONE = 1,
  TWO = 2,
  THREE = 3,
  ADL = 99,
}

export interface Service {
  /**Service Type */
  type?: HDR_SERVICES_TYPE
  /**List of axis/channels of that service */
  axisData?: {
    axis?: Axis
    configs?: ExtraConfig
  }[]
  /**Service name*/
  name: string
  /**Service mac*/
  mac: string
}
