import { toast } from "react-toastify"
import { ModalHedro } from "../../../../../../../../components/ModalHedro"
import { useDeleteHistoryAssetMutation } from "../../../../../../../../store/api/assets/slice"
import { useAppTranslate } from "../../../../../../../../translate/useAppTranslate"
import { Container } from "./styles"

interface ModalDeletEventProps {
  handleCloseModal: () => void
  assetId: string
  eventId: string
}

export const ModalDeletEvent = ({
  handleCloseModal,
  assetId,
  eventId,
}: ModalDeletEventProps) => {
  const [deleteEvent, mutation] = useDeleteHistoryAssetMutation()

  const {
    assets: { history },
  } = useAppTranslate()

  const handleDelete = async () => {
    deleteEvent({
      assetId: assetId,
      eventId: eventId,
    })
      .unwrap()
      .then(() => {
        handleCloseModal()
      })
      .catch(() => {
        toast.error(history.errorDeletingEvent)
      })
  }

  return (
    <ModalHedro
      title={history.deleteEvent}
      onClose={() => handleCloseModal()}
      onAction={handleDelete}
      actionLabel={history.complete}
      isActionsDisabled={mutation.isLoading}
    >
      <Container>
        {history.byPerformingThisActionThisEventWillBePermanentlyDeleted}
      </Container>
    </ModalHedro>
  )
}
