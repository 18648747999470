import { Skeleton } from "@mui/material"
import { TitleAndTag } from "../../../ui/TitleAndTag"
import * as S from "./styles"
import { CardExpand, Wrapper } from "./styles"
import { DotsThreeVertical } from "phosphor-react"

type CardHeaderProps = {
  title: string
  subTitle?: string
  createdAt?: Date
  icon: JSX.Element | undefined
  status?: "read" | "unread"
  type: "notification" | "message"
  skeleton?: boolean
  onClick?: () => void
}

export const CardHeader = ({
  title,
  subTitle = "",
  createdAt,
  icon,
  status = "read",
  type,
  skeleton = false,
  onClick,
}: CardHeaderProps) => {
  return (
    <S.CardHeader>
      {skeleton ? (
        <>
          <Skeleton variant='text' width={"80%"} />
        </>
      ) : (
        <Wrapper>
          <TitleAndTag
            title={title}
            tag={subTitle}
            icon={icon}
            status={status}
            type={type}
            date={createdAt}
          />
          <CardExpand onClick={onClick}>
            <DotsThreeVertical size={24} />
          </CardExpand>
        </Wrapper>
      )}
    </S.CardHeader>
  )
}
