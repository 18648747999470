import { Title } from "../../Title"
import { CardDate, CardIcon, CardIconAndTitle, CardTag } from "./styles"

type TitleAndTagProps = {
  title?: string
  tag?: string
  icon?: React.ReactNode
  status: "read" | "unread"
  type: "notification" | "message"
  date?: Date | undefined
}

export const TitleAndTag = ({
  title = "",
  tag = "",
  icon,
  status = "read",
  type,
  date = undefined,
}: TitleAndTagProps) => {
  const DateToString = (date: Date | undefined) => {
    if (!date) return ""
    return date.toLocaleString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    })
  }

  const dateInString = DateToString(date)

  return (
    <div>
      <CardIconAndTitle $status={status}>
        <CardIcon type={type}>{icon}</CardIcon>
        <Title label={title} size='xs' weight='semibold' />
      </CardIconAndTitle>
      <CardDate>{dateInString}</CardDate>
      <CardTag>{tag}</CardTag>
    </div>
  )
}
