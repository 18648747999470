import { MutationLifecycleApi } from "@reduxjs/toolkit/dist/query/endpointDefinitions"
import {
  CreateAssetRequest,
  DeleteAssetAttachmentRequest,
  DeleteAssetResponse,
  LinkSensorData,
  UnlinkSensorRequest,
  UploadAssetImageRequest,
  UploadAssetImageResponse,
  UploadAssetAttachmentRequest,
  UpdateAssetRequest,
  AssetAttachmentsResponse,
  CreateAssetsHistory,
  DeleteAssetHistoryRequest,
  EditAssetHistoryRequest,
} from "./entities"
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/dist/query/react"
import { assetsApi } from "./slice"
import { AssetDetails } from "../../../pages/v3/Assets/entities/AssetDetails"
import { AssetHistory } from "../../../utils/entities/assets"

type BaseQueryFnType = BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  {},
  FetchBaseQueryMeta
>

type MutationType<Request, Response> = MutationLifecycleApi<
  Request,
  BaseQueryFnType,
  Response,
  "assetsApi"
>

export const onUpdateQueryStarted = async (
  { assetId }: UpdateAssetRequest,
  { dispatch, queryFulfilled }: MutationType<UpdateAssetRequest, AssetDetails>
) => {
  try {
    const response = (await queryFulfilled).data

    dispatch(
      assetsApi.util.updateQueryData(
        "fetchAssets",
        { services: false },
        (draft) => {
          draft[response.id].data = {
            id: response.id,
            name: response.name,
            typeId: response.assetType.id,
          }
        }
      )
    )

    dispatch(
      assetsApi.util.updateQueryData(
        "fetchAssetDetails",
        response.id,
        (draft) => {
          draft.description = response.description
          draft.name = response.name
          draft.assetType = response.assetType
          draft.propertyValues = response.propertyValues
        }
      )
    )
  } catch (error) {
    console.error(error)
  }
}

export const onUploadAssetImageQueryStarted = async (
  request: UploadAssetImageRequest,
  {
    dispatch,
    queryFulfilled,
  }: MutationType<UploadAssetImageRequest, UploadAssetImageResponse>
) => {
  try {
    const response = (await queryFulfilled).data

    dispatch(
      assetsApi.util.updateQueryData(
        "fetchAssetDetails",
        request.assetId,
        (draft) => {
          draft.assetPicture = response.url
        }
      )
    )
  } catch (error) {
    console.error(error)
  }
}

export const onDeleteAssetImageQueryStarted = async (
  request: string,
  { dispatch, queryFulfilled }: MutationType<string, void>
) => {
  try {
    await queryFulfilled

    dispatch(
      assetsApi.util.updateQueryData("fetchAssetDetails", request, (draft) => {
        draft.assetPicture = null
      })
    )
  } catch (error) {
    console.error(error)
  }
}

export const onCreateQueryStarted = async (
  { parentId }: CreateAssetRequest,
  { dispatch, queryFulfilled }: MutationType<CreateAssetRequest, AssetDetails>
) => {
  try {
    const { id, name, assetType } = (await queryFulfilled).data

    dispatch(
      assetsApi.util.updateQueryData(
        "fetchAssets",
        { services: false },
        (draft) => {
          const children = draft[parentId].children ?? []

          draft[parentId].children = [...children, id]
          draft[parentId].isFolder = true

          draft[id] = {
            data: {
              id,
              name,
              typeId: assetType.id,
            },
            index: id,
            children: [],
            isFolder: false,
          }
        }
      )
    )
  } catch (error) {
    console.error(error)
  }
}

export const onDeleteAssetEventQueryStarted = async (
  { assetId, eventId }: DeleteAssetHistoryRequest,
  { dispatch, queryFulfilled }: MutationType<DeleteAssetHistoryRequest, void>
) => {
  await queryFulfilled

  dispatch(
    assetsApi.util.updateQueryData(
      "fetchAssetHistory",
      {
        assetId,
        offset: 0,
        limit: 10,
        orderBy: "created_at",
        sortBy: "ASC",
        search: "",
        recursive: true,
      },
      (draft) => {
        draft.data = draft.data.filter((item) => item.id !== eventId)
        draft.total -= 1
      }
    )
  )
}

export const onUpdateAssetEventQueryStarted = async (
  { eventId, assetId }: EditAssetHistoryRequest,
  {
    dispatch,
    queryFulfilled,
  }: MutationType<EditAssetHistoryRequest, AssetHistory>
) => {
  try {
    const response = (await queryFulfilled).data

    dispatch(
      assetsApi.util.updateQueryData(
        "fetchAssetHistory",
        {
          assetId,
          offset: 0,
          limit: 10,
          orderBy: "created_at",
          sortBy: "ASC",
          search: "",
          recursive: true,
        },
        (draft) => {
          const index = draft.data.findIndex((item) => item.id === eventId)
          if (index !== -1) {
            draft.data[index] = response
          }
        }
      )
    )
  } catch (error) {
    console.error("Error updating asset history cache:", error)
  }
}

export const upCreateAssetHistoryCache = async (
  { assetId }: CreateAssetsHistory,
  { dispatch, queryFulfilled }: MutationType<CreateAssetsHistory, AssetHistory>
) => {
  try {
    const response = (await queryFulfilled).data

    dispatch(
      assetsApi.util.updateQueryData(
        "fetchAssetHistory",
        {
          assetId,
          offset: 0,
          limit: 10,
          orderBy: "created_at",
          sortBy: "ASC",
          search: "",
          recursive: true,
        },
        (draft) => {
          draft.data.push({
            id: response.id,
            assetId: response.assetId,
            eventGroup: response.eventGroup,
            eventType: response.eventType,
            eventInfo: response.eventInfo,
            createdAt: response.createdAt,
            deletedAt: response.deletedAt,
            executedAt: response.executedAt,
            alteredBy: response.alteredBy,
            latestAssetName: response.latestAssetName,
          })
          draft.total += 1
        }
      )
    )
  } catch (error) {
    console.error("Error updating asset history cache:", error)
  }
}

export const onDeleteQueryStarted = async (
  id: string,
  { dispatch, queryFulfilled }: MutationType<string, DeleteAssetResponse>
) => {
  await queryFulfilled

  dispatch(
    assetsApi.util.updateQueryData(
      "fetchAssets",
      { services: false },
      (draft) => {
        const keys = Object.keys(draft)

        for (let i = 0; i < keys.length; i++) {
          const key = keys[i]

          if (!draft[key].children) continue
          if (!draft[key].children!.includes(id)) continue

          draft[key].isFolder = draft[key].children!.length > 1

          draft[key].children = draft[key].children!.filter(
            (child) => child !== id
          )
        }

        delete draft[id]
      }
    )
  )
}

export const onUnlinkSensorQueryStarted = async (
  request: UnlinkSensorRequest,
  { dispatch, queryFulfilled }: MutationType<LinkSensorData, void>
) => {
  try {
    await queryFulfilled

    dispatch(
      assetsApi.util.updateQueryData(
        "fetchAssetDetails",
        request.assetId,
        (draft) => (draft.linkedSensor = undefined)
      )
    )
  } catch (error) {
    console.error(error)
  }
}

export const onUploadAssetAttachmentQueryStarted = async (
  request: UploadAssetAttachmentRequest,
  {
    dispatch,
    queryFulfilled,
  }: MutationType<UploadAssetAttachmentRequest, AssetAttachmentsResponse>
) => {
  try {
    const response = (await queryFulfilled).data

    dispatch(
      assetsApi.util.updateQueryData(
        "fetchAssetAttachments",
        request.assetId,
        (draft) => {
          draft.push(response)
        }
      )
    )
  } catch (error) {
    console.error(error)
  }
}

export const onDeleteAssetAttachmentQueryStarted = async (
  request: DeleteAssetAttachmentRequest,
  { dispatch, queryFulfilled }: MutationType<DeleteAssetAttachmentRequest, void>
) => {
  try {
    await queryFulfilled

    dispatch(
      assetsApi.util.updateQueryData(
        "fetchAssetAttachments",
        request.assetId,
        (draft) => {
          const index = draft.findIndex(
            (item) => item.id === request.attachmentId
          )
          if (index !== -1) {
            draft.splice(index, 1)
          }
        }
      )
    )
  } catch (error) {
    console.error(error)
  }
}
